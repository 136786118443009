import React from 'react';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import CreateSchemaModal from 'app/schema-enforcement/SchemasTab/modals/CreateSchemaModal';

import NewSchemaButton from './NewSchemaButton';

export default function NewSchemaModalLauncher() {
  return <ModalLauncher Launcher={NewSchemaButton} Modal={CreateSchemaModal} />;
}
