import React from 'react';
import PropTypes from 'prop-types';

import { FieldController } from '@metarouter-private/mr-mui';

import { MrCodeEditor } from '@ion/components';

export default function FormCodeEditor({ disabled, ...codeEditorProps }) {
  return (
    <FieldController
      render={({ field, fieldState }) => {
        const { value, onBlur, onChange, name } = field;
        const { error } = fieldState;

        return (
          <MrCodeEditor
            {...codeEditorProps}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            errorMsg={error?.message}
            value={value}
            readOnly={disabled}
          />
        );
      }}
    />
  );
}

FormCodeEditor.propTypes = {
  disabled: PropTypes.bool,
};
