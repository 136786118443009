import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import EditButton from './EditButton';
import DeleteButton from './DeleteButton';

const STACK_PROPS = {
  alignItems: 'center',
  direction: 'row',
};

export default function ActionsCell(props) {
  const {
    onEdit,
    canEdit,
    onDelete,
    canDelete,
    editDisabledMessage,
    deleteDisabledMessage,
    editButtonDataTestId,
    deleteButtonDataTestId,
  } = props;

  const editButtonProps = {
    onClick: onEdit,
    disabled: !canEdit,
    disabledMessage: editDisabledMessage,
    dataTestId: editButtonDataTestId,
  };

  const deleteButtonProps = {
    onClick: onDelete,
    disabled: !canDelete,
    disabledMessage: deleteDisabledMessage,
    dataTestId: deleteButtonDataTestId,
  };

  return (
    <Stack {...STACK_PROPS}>
      <EditButton {...editButtonProps} />
      <DeleteButton {...deleteButtonProps} />
    </Stack>
  );
}

ActionsCell.propTypes = {
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  editDisabledMessage: PropTypes.string,
  deleteDisabledMessage: PropTypes.string,
  editButtonDataTestId: PropTypes.string,
  deleteButtonDataTestId: PropTypes.string,
};
