import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Logout from 'app/logout';
import ProtectedRoute from 'app/components/route/protected';
import { routes } from 'app/constants';
import pages from 'app/pages';
import SchemaEnforcementPage from 'app/schema-enforcement';
import SchemaLibraryPage from 'app/schema-enforcement/SchemaLibraryPage';
import DashboardPage from 'app/dashboard';
import ClustersPage from 'app/clusters';
import PipelinesPage from 'app/pipelines';
import SettingsPage from 'app/settings';
//integrations Refactor
import IntegrationsPage from 'app/integrations';
import NewIntegrationPage from 'app/integrations/add';
import PlaybookEditorPage from 'app/playbook-editor';
import { NotFoundPage, NoOrgsPage, NoEmailPage } from 'app/error-pages';
import { useAuth0 } from '@auth0/auth0-react';
import AuditLogsPage from '../audit-logs';
import LoginRedirectPage from '../login-redirect';
import EventMonitoringPage from '../event-monitoring';
import SchemaViolationsPage from 'app/schema-violations/SchemaViolationsPage';

export const Routes = () => {
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated || (!isAuthenticated && window.location.pathname === '/logout')) {
    localStorage.setItem('redirectPath', '/');
  }

  const storageRedirectPath = localStorage.getItem('redirectPath');
  const rootRedirect =
    storageRedirectPath && storageRedirectPath !== routes.home ? storageRedirectPath : routes.dashboard;
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path={routes.loginRedirect} component={LoginRedirectPage} />
        <ProtectedRoute exact path={routes.home} redirect={rootRedirect} />

        {/* Integrations */}
        <ProtectedRoute exact path={routes.integrations} component={IntegrationsPage} />
        <ProtectedRoute exact path={routes.newIntegration} component={NewIntegrationPage} />
        <ProtectedRoute exact path={routes.editPlaybook} component={PlaybookEditorPage} />

        {/* Clusters */}
        <ProtectedRoute exact path={routes.clusters} component={ClustersPage} />

        {/* Pipelines */}
        <ProtectedRoute exact path={routes.pipelines} component={PipelinesPage} />

        {/* Schema Violations */}
        <ProtectedRoute exact path={routes.schemaViolations} component={SchemaViolationsPage} />

        {/* Audit Logs */}
        <ProtectedRoute exact path={routes.auditLog} component={AuditLogsPage} />

        {/* Event Schema Report and Event Debugger */}
        <ProtectedRoute exact path={routes.eventMonitoring} component={EventMonitoringPage} />

        {/* Schema Enforcement */}
        <ProtectedRoute exact path={routes.schemas} component={SchemaEnforcementPage} />
        <ProtectedRoute exact path={routes.schemaLibrary} component={SchemaLibraryPage} />

        {/*Dashboard */}
        <ProtectedRoute exact path={routes.dashboard} component={DashboardPage} />

        {/* AnalyticsJSBuilder */}
        <ProtectedRoute path={routes.analyticsJSBuilder} component={pages.AnalyticsJSBuilderPage} />

        {/* Bulk Stage and Deploy Routes */}
        <ProtectedRoute path={routes.selectStageOrDeploy} component={pages.SelectStageOrDeployPage} />
        <ProtectedRoute path={routes.bulkSelectRevisions} component={pages.BulkSelectRevisionsPage} />
        <ProtectedRoute path={routes.bulkSelectPipelines} component={pages.BulkSelectPipelinesPage} />
        <ProtectedRoute path={routes.bulkDeployPipelines} component={pages.BulkDeployPipelinesPage} />

        {/* Settings Routes */}
        <ProtectedRoute exact path={routes.settings} component={SettingsPage} />

        <Route exact path={routes.logOut} component={Logout} />
        <Route exact path={routes.noOrgsFound} component={NoOrgsPage} />
        <Route exact path={routes.noUserEmail} component={NoEmailPage} />

        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};
