import React from 'react';
import PropTypes from 'prop-types';

import { FormTextField, FormFieldContext } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';

import useValidateUniqueName from './useValidateUniqueName';

const DISABLED_MESSAGE = "A schema name cannot be changed after it's been assigned to a schema library";
const NAME_FIELD_BASE_PROPS = {
  label: 'Schema Name',
  name: 'name',
  required: true,
};

export default function NameField({ defaultValue, disabled }) {
  const validateUniqueName = useValidateUniqueName(defaultValue, disabled);

  return (
    <WithTooltip hasPermission={!disabled} title={DISABLED_MESSAGE}>
      <FormFieldContext
        {...NAME_FIELD_BASE_PROPS}
        disabled={disabled}
        defaultValue={defaultValue}
        rules={{ validate: validateUniqueName }}
      >
        <FormTextField />
      </FormFieldContext>
    </WithTooltip>
  );
}

NameField.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
};
