import { useInsertSchema } from '@ion/api';

export default function useCreateSchema({ onClose }) {
  const [createSchema] = useInsertSchema({ onCompleted: onClose });

  const onSubmit = ({ name, description, jsonSchema }) => {
    const trimmedSchema = jsonSchema.trim();

    createSchema({ name, description, jsonSchema: trimmedSchema });
  };

  return onSubmit;
}
