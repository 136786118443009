import { gql } from '@apollo/client';
import permissions from 'api/Apollo/permissions';
import useQuery from 'api/hooks/useQuery';
import schema from './schema';

export const GET_ALL_SCHEMAS = gql`
  query AllSchemas {
    schemas {
      ...schema
    }
  }
  ${schema}
`;

export default function useQueryAllSchemas({ variables, ...options } = {}) {
  const { data, loading, isAllowed, error } = useQuery(GET_ALL_SCHEMAS, {
    variables, // can apply any transforms or default vars here
    context: { permission: permissions.readSchemas },
    // Disabling the cache so we always query the latest version after a schema is edited.
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.schemas,
    loading,
    isAllowed,
    error,
  };
}
