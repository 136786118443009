import React from 'react';
import PropTypes from 'prop-types';

import { Edit03Icon, IconButton } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';
import tooltipTitle from 'app/schema-enforcement/SchemaLibraryPage/EventSchemasTable/tooltipTitle';
import { useEventSchemaButton } from './useEventSchemaButton';

export default function EditEventSchemaButton({ row }) {
  const { hasPermission, onClick } = useEventSchemaButton({ type: 'edit', row });

  return (
    <WithTooltip hasPermission={hasPermission} title={tooltipTitle}>
      <IconButton data-test-id="editEventSchemaButton" disabled={!hasPermission} onClick={onClick}>
        <Edit03Icon />
      </IconButton>
    </WithTooltip>
  );
}

EditEventSchemaButton.propTypes = {
  row: PropTypes.object.isRequired,
};
