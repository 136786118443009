import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permissions from 'api/Apollo/permissions';
import { SCHEMA_LIBRARY_WITH_VERSIONS_QUERY } from 'api/schema-libraries/useQuerySchemaLibraryWithVersions';
import {
  mutation,
  buildVariables as buildInsertDuplicateSchemaLibraryVersionVariables,
} from './insert-duplicate-schema-library-version-mutation-builder';

export default function useInsertDuplicateSchemaLibraryVersion(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_library_version_created');
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    refetchQueries: [SCHEMA_LIBRARY_WITH_VERSIONS_QUERY],
    context: { permission: permissions.createSchemas },
  });

  const insertDuplicateSchemaLibraryVersionMutation = async ({ schemaLibraryVersionId }) => {
    const response = await executeMutation({
      variables: buildInsertDuplicateSchemaLibraryVersionVariables({
        schemaLibraryVersionId,
      }),
    });

    if (!response.errors) {
      trackWithUser();
    }

    return response;
  };

  return [insertDuplicateSchemaLibraryVersionMutation, response];
}
