import React from 'react';
import PropTypes from 'prop-types';

import { FormSelect, FormFieldContext } from '@metarouter-private/mr-mui';
import { useQueryAllSchemaLibraries } from '@ion/api';
import { buildSelectOptions } from '@ion/mrdash';

export const SCHEMA_LIBRARY_ID = 'schemaLibraryId';

const SCHEMA_LIBRARY_SELECT = {
  name: SCHEMA_LIBRARY_ID,
  label: 'Select a Schema Library',
  required: true,
};

export default function SchemaLibrarySelect({ schemaLibraryId }) {
  const { data } = useQueryAllSchemaLibraries();
  const options = buildSelectOptions({ items: data, labelKey: 'name', valueKey: 'id' });

  return (
    <FormFieldContext {...SCHEMA_LIBRARY_SELECT} defaultValue={schemaLibraryId}>
      <FormSelect options={options} />
    </FormFieldContext>
  );
}

SchemaLibrarySelect.propTypes = {
  schemaLibraryId: PropTypes.string,
};
