import {
  isNil as _isNil,
  isFinite as _isFinite,
  isInteger as _isInteger,
  isBoolean as _isBoolean,
  isString as _isString,
  isEqual as _isEqual,
  isEmpty as _isEmpty,
  snakeCase as _snakeCase,
  gte as _gte,
  lte as _lte,
} from 'lodash';
import floatingPointRegex from 'floating-point-regex';
import isValidDomain from 'is-valid-domain';
import isValidIp from 'is-ip';
import generatePipelinesList from '@ion/app/src/pipelines/bulk-stage-and-deploy/bulk-select-revisions/generate-pipelines-list';

/**
 *
 * Given a value and an array of validators, returns the error message from the first failing
 * validation, or null if all validations pass
 *
 * @param {string} value
 * @param {Function[]} validators Array of validator functions
 * @returns {string} Error message or null
 */
const applyValidators = ({ value, validators = [], prefix = '', suffix = '' }) => {
  const errors = validators
    .map(validator => {
      // ignore prefix for the required validator, use it for all other cases
      if (validator.name === '_validateRequired') {
        return validator(value);
      } else {
        return validator(`${prefix}${value}${suffix}`);
      }
    })
    .filter(result => !_isNil(result));

  return errors.length ? errors[0] : null;
};

/**
 *
 * Base signature for inner function returned by validators
 * (value: any) => string
 *
 * @param {any} value Input value to be validated, any valid JSON data type
 * @returns {string} Error message supplied by the outer function
 */

/**
 *
 * Passes if the value is not empty
 *
 * @param {string} msg
 * @returns {Function}
 */
const validateRequired = (msg = 'Required field') => {
  // this named function ↓↓ is used in applyValidators to identify this validator by its name property
  return function _validateRequired(value) {
    const isEmpty = value.trim().length < 1;

    return isEmpty ? msg : null;
  };
};

/**
 *
 * Passes if the value is a finite number
 *
 * @param {string} msg
 * @returns {Function}
 */
const validateNumber = (msg = 'Input must be a number') => {
  return value => {
    const isValid = _isFinite(Number(value)); // try casting the value to a number

    return !isValid ? msg : null;
  };
};

/**
 *
 * Passes if the value is an integer
 *
 * @param {string} msg
 * @returns {Function}
 */
const validateInteger = (msg = 'Input must be an integer') => {
  return value => {
    const isValid = _isInteger(Number(value));

    return !isValid ? msg : null;
  };
};

/**
 *
 * Passes if the value is a floating point number
 *
 * @param {string} msg
 * @returns {Function}
 */
const validateFloat = (msg = 'Input must be a floating point number') => {
  return value => {
    const isValid = floatingPointRegex().test(value);

    return !isValid ? msg : null;
  };
};

/**
 *
 * Passes if the value is a boolean, string true/false, or 0/1
 *
 * @param {string} msg
 * @returns {Function}
 */
const validateBoolean = (msg = 'Input must be a boolean value') => {
  const boolStrings = ['true', 'false', '0', '1'];

  return value => {
    const isValid =
      _isBoolean(value) ||
      (_isString(value) && boolStrings.some(bool => bool === value.toLowerCase())) ||
      value === 0 ||
      value === 1;

    return !isValid ? msg : null;
  };
};

/**
 *
 * Passes if the value matches the provided regular expression
 *
 * @param {object} regex Regular expression to match against
 * @param {string} msg
 * @returns {Function}
 */
const validatePattern = (regex, msg = 'Invalid pattern') => {
  return value => {
    return !regex.test(value) ? msg : null;
  };
};

/**
 *
 * Passes if the input value and otherValue are not equal
 *
 * @param {any} otherValue
 * @param {string} msg
 */
const validateNotEqual = (otherValue, msg) => {
  return value => {
    return _isEqual(value, otherValue) ? msg : null;
  };
};

const validateEmail = (msg = 'Input does not appear to be an email address') => {
  // https://stackoverflow.com/a/9204568
  return validatePattern(/\S+@\S+\.\S+/, msg);
};

const validateMinLength = (length, msg = `Input must be at least ${length} characters`) => {
  return value => {
    return value.length < length ? msg : null;
  };
};

/**
 *
 * Passes if the value is less than or equal to the provided length
 *
 * @param {number} length
 * @param {string} msg
 * @returns {Function}
 */
const validateMaxLength = (length, msg = `Input must be ${length} characters or less`) => {
  return value => {
    return value.length > length ? msg : null;
  };
};

/**
 *
 * Passes if the value is in the provided range
 *
 * @param {string} msg
 * @returns {Function}
 */
const validateInRange = (min, max, msg = `Value must be between ${min} and ${max}`) => {
  return value => {
    return _gte(Number(value), min) && _lte(Number(value), max) ? null : msg;
  };
};

// Helper function used for validateUrl and validateCsvUrls
function isValidURL(value, allowForwardSlash = true, allowHTTP = true) {
  let isValid = true;
  const urlSlashes = value.match(/\/\//g)?.length || 0;
  try {
    if (urlSlashes > 1) {
      return false;
    }
    const url = new URL(value);
    isValid = isValidDomain(url.host) || isValidIp(url.host);
    if (isValid && !allowForwardSlash) {
      isValid = !value.endsWith('/');
    }
    if (isValid && !allowHTTP) {
      isValid = !value.startsWith('http://');
    }
  } catch (_) {
    isValid = false;
  }
  return isValid;
}

/**
 * Validates that a URL does not contain a path when a condition is met
 * @param {boolean} condition - The condition that determines if the URL should be checked for paths
 * @param {string} [msg='URL must not contain a path'] - Custom error message
 * @returns {Function} Validator function that returns null if valid, error message if invalid
 */
export const validateNoPath = (condition, msg = 'URL must not contain a path') => {
  return value => {
    if (!condition) {
      return null; // Explicitly return null when validation is not needed
    }

    try {
      const url = new URL(value);
      return url.pathname !== '/' && url.pathname !== '' ? msg : null; // Explicitly return null for valid case
    } catch (e) {
      return null; // Explicitly return null when URL parsing fails
    }
  };
};

/**
 *
 * Passes if the value is a valid URL
 *
 * @param {string} msg
 * @param {Boolean} allowForwardSlash allow forward slashes in URL
 * @returns {Function}
 */

const validateUrl = (msg = `Value must be a URL`, allowForwardSlash = true) => {
  let isValid = true;

  return value => {
    isValid = isValidURL(value, allowForwardSlash, true);

    return isValid ? null : msg;
  };
};

/**
 *
 * Passes if the value is a valid URL
 *
 * @param {string} msg
 * @param {Boolean} allowForwardSlash allow forward slashes in URL
 * @returns {Function}
 */

const validateCsvUrls = (msg = 'Invalid format, must be a comma-separated list of URLs ("https://" included)') => {
  let isValid = true;

  return value => {
    if (value !== '') {
      let isValidArray = [];
      value.split(',').forEach(syncURL => {
        isValidArray.push(isValidURL(syncURL.trim(), true, false));
      });
      isValid = isValidArray.every(v => v === true);
    }
    return isValid ? null : msg;
  };
};

/**
 *
 * Passes if the value is not contained in the given array
 *
 * @param {array} array
 * @param {string} msg
 */
const validateNotInArray = (array, msg = 'Input must be unique') => {
  return value => {
    return array.includes(value) ? msg : null;
  };
};

/**
 *
 * Passes if the value is in snake case format
 *
 * @param {string} msg
 */
const validateSnakeCase = (msg = 'Input must be in snake case format') => {
  return value => {
    const isValid = value === _snakeCase(value);

    return !isValid ? msg : null;
  };
};

/**
 *
 * Passes if pipeline vars for selected revisions match
 *
 * @param {object} data
 * @param {array} playbooks
 * @param {string} msg
 */
const validatePipelineVars = (
  data,
  playbooks,
  msg = 'Pipeline variables are incompatible between selected revisions'
) => {
  if (data.currentRevision?.value !== '' && data.updatedRevision?.value !== '') {
    const updatedVars = playbooks?.find(p => p.id === data.updatedRevision?.value)?.pipeline_variables.map(p => p.name);
    if (data.currentRevision?.value?.startsWith('new-integration-')) {
      return () => {
        const isValid = _isEmpty(updatedVars);
        return !isValid ? msg : null;
      };
    }
    const currentVars = playbooks?.find(p => p.id === data.currentRevision?.value)?.pipeline_variables.map(p => p.name);
    const matchingVars = JSON.stringify(currentVars) === JSON.stringify(updatedVars);
    return () => {
      const isValid = matchingVars;
      return !isValid ? msg : null;
    };
  } else {
    return () => {
      return null;
    };
  }
};

/**
 *
 * Passes if there are any pipelines with the current revision staged
 *
 * @param {array} pipelines
 * @param {string} currentRevision
 * @param {string} msg
 */
const validateStagedRevisions = (
  pipelines,
  currentRevision,
  msg = 'No pipelines found with selected "Current Revision" staged'
) => {
  if (currentRevision?.startsWith('new-integration-')) {
    msg = 'All pipelines have selected integration staged';
  }

  const pipelinesList = generatePipelinesList(pipelines, currentRevision);

  return () => {
    const isValid = !_isEmpty(pipelinesList);
    return !isValid ? msg : null;
  };
};

/**
 *
 * Passes if the WriteKey is alphanumeric with dashes and/or underscores.
 *
 * @param {string} writeKey
 */
const validateWriteKey = writeKey => {
  const hasSpaces = /\s/.test(writeKey);
  const hasSpecialChars = /[^\w\s-]/.test(writeKey);
  if (hasSpaces && hasSpecialChars) {
    return 'Special characters other than dash and underscore are not allowed. Spaces are not allowed. Please update and retry.';
  }
  if (hasSpaces) {
    return 'Spaces are not allowed. Please update and retry.';
  }
  if (hasSpecialChars) {
    return 'Special characters other than dash and underscore are not allowed. Please update and retry.';
  }
  return null;
};

export {
  validateRequired,
  validateNumber,
  validateInteger,
  validateFloat,
  validatePattern,
  validateBoolean,
  validateNotEqual,
  validateEmail,
  validateMinLength,
  validateMaxLength,
  validateInRange,
  validateUrl,
  validateCsvUrls,
  validateNotInArray,
  validateSnakeCase,
  validatePipelineVars,
  validateStagedRevisions,
  validateWriteKey,
  applyValidators,
};
