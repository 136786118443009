import React from 'react';

import { FormModal, Edit03Icon } from '@metarouter-private/mr-mui';

import SchemaForm from 'app/schema-enforcement/SchemasTab/modals/shared/SchemaForm';
import SCHEMA_FORM_MODAL_CONFIG from 'app/schema-enforcement/SchemasTab/modals/shared/SCHEMA_FORM_MODAL_CONFIG';
import SchemaVersionSelect from './SchemaVersionSelect';
import useEditSchemaModalProps from './useEditSchemaModalProps';

const STATIC_MODAL_PROPS = {
  ...SCHEMA_FORM_MODAL_CONFIG,
  title: 'Edit Schema',
  TitleIcon: Edit03Icon,
  submitLabel: 'Save New Version',
};

export default function EditSchemaModal() {
  const { open, onClose, onSubmit, onVersionChange, versionOptions, selectedVersion, name, nameDisabled, description } =
    useEditSchemaModalProps();

  return (
    <FormModal {...STATIC_MODAL_PROPS} onSubmit={onSubmit} onClose={onClose} open={open}>
      <SchemaVersionSelect value={selectedVersion} options={versionOptions} onChange={onVersionChange} />

      <SchemaForm versionId={selectedVersion} name={name} nameDisabled={nameDisabled} description={description} />
    </FormModal>
  );
}
