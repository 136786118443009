import { METHOD, PATH } from '../control-api';
import { mutation as controlProxyMutation } from 'api/control/control-proxy-mutation-builder';

export const mutation = controlProxyMutation;

export function buildVariables({ writekey, clusterId }) {
  return {
    path: PATH.PipelineSchemaLibrary,
    method: METHOD.DELETE,
    body: {
      writeKey: writekey,
    },
    clusterId,
    writekey,
  };
}
