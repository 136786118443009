import useLazyQuery from '../hooks/useLazyQuery';
import permissions from '../Apollo/permissions';
import { buildVariables, GET_EVENT_NAMES } from './event-names-query-builder';

export default function useLazyQueryEventNames({ ...options } = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(GET_EVENT_NAMES, {
    context: { permission: permissions.readEventNames },
    // Not using the cache so we can dynamically refresh whenever new schema created.
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const getSchemas = pagination => {
    const variables = buildVariables(pagination);
    return executeQuery({ variables });
  };

  return [getSchemas, { ...transformData(data), ...rest }];
}

function transformData(data) {
  if (!data) {
    return {
      data: [],
      pagination: { count: null },
    };
  }

  const { eventNames, eventNamesAggregate } = data;

  return {
    data: transformEventNames(eventNames),
    pagination: { count: eventNamesAggregate.aggregate.count },
  };
}

function transformEventNames(eventNames) {
  return eventNames.map(eventName => {
    return {
      id: eventName.id,
      name: eventName.name,
      description: eventName.description,
      assignmentsCount: eventName.schemaLibraryEventSchemasAggregate.aggregate.count,
    };
  });
}
