import { useEffect } from 'react';

import { useLazyQueryEventSchemas } from '@ion/api';

import { useSchemaLibraryPageContext } from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';

const EVENT_SCHEMAS_PER_PAGE = 10;

export default function useEventSchemasTable() {
  const { activeVersion, setIsPublishable } = useSchemaLibraryPageContext();
  const schemaLibraryVersionId = activeVersion.id;

  const [getEventSchemas, { data, pagination, loading }] = useLazyQueryEventSchemas();

  useEffect(() => {
    getEventSchemas({
      page: 1,
      perPage: EVENT_SCHEMAS_PER_PAGE,
      schemaLibraryVersionId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaLibraryVersionId]);

  useEffect(() => {
    const isPublishable = !!data.length;
    setIsPublishable(isPublishable);
  }, [data, setIsPublishable]);

  const onFetchPage = ({ page, perPage }) => {
    getEventSchemas({
      page,
      perPage,
      schemaLibraryVersionId,
    });
  };

  return {
    onFetchPage,
    data,
    pagination,
    perPage: EVENT_SCHEMAS_PER_PAGE,
    loading,
  };
}
