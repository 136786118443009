// This function takes in the googleTagSettings defined in the builder file and transforms it into the format that is expected by the database
// reference googleTagSettings schema in packages/builder-server/src/services/builder/validation/syncInjector/validateGoogleGtagSettings.ts

const transformGoogleTagSettingsForDb = googleTagSettings => {
  if (!googleTagSettings) {
    return null;
  }

  return {
    tagId: googleTagSettings.tagID,
    consentEnabled: googleTagSettings.consent.enabled,
    consentExplicitConsent: googleTagSettings.consent.explicitConsent,
    consentSyncCategories: googleTagSettings.consent.syncCategories,
  };
};

export default transformGoogleTagSettingsForDb;
