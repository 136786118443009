import { useMemo } from 'react';
import determineErrorMessage from './determineErrorMessage';

const DEFAULT_PROPS = {
  lang: 'yaml',
  rows: 30,
  testId: 'YamlCodeEditor',
};

export default function useYamlCodeEditorProps({ value, customErrorFn, ...rest }) {
  const errorMsg = useMemo(
    () => determineErrorMessage(value, customErrorFn),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return {
    ...DEFAULT_PROPS,
    ...rest,
    value,
    errorMsg,
  };
}
