import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Trash02Icon } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';

export default function DeleteButton({ onClick, disabled, disabledMessage, dataTestId }) {
  if (!onClick) {
    return null;
  }

  return (
    <WithTooltip hasPermission={!disabled} title={disabledMessage}>
      <IconButton data-test-id={dataTestId} onClick={onClick} disabled={disabled}>
        <Trash02Icon />
      </IconButton>
    </WithTooltip>
  );
}

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  dataTestId: PropTypes.string,
};
