import { useModalContext } from 'app/schema-enforcement/SchemaLibraryPage/ModalContext';
import useCanEditVersion from 'app/schema-enforcement/SchemaLibraryPage/hooks/useCanEditVersion';

export const useEventSchemaButton = ({ type, row }) => {
  const hasPermission = useCanEditVersion();
  const { deleteEventSchema, editEventSchema } = useModalContext();

  const onClick = () => {
    switch (type) {
      case 'edit':
        return editEventSchema(row);
      case 'delete':
        return deleteEventSchema(row);
    }
  };

  return { hasPermission, onClick };
};
