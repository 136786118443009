import React from 'react';

import { ModalContextProvider } from 'app/shared/contexts/modal';

import SchemasTable from './SchemasTable';
import EditSchemaModal from './modals/EditSchemaModal';
import ConfirmDeleteSchemaModal from './modals/ConfirmDeleteSchemaModal';

export default function SchemasTab() {
  return (
    <ModalContextProvider>
      <SchemasTable />

      <EditSchemaModal />
      <ConfirmDeleteSchemaModal />
    </ModalContextProvider>
  );
}
