import React from 'react';

import { Box } from '@metarouter-private/mr-mui';

import { MrSpinner } from '@ion/components';

const LOADING_MESSAGE = 'Loading Schema Library';
const BOX_PROPS = {
  display: 'flex',
  justifyContent: 'center',
  width: 1,
  mt: 2,
};

export default function SchemaLibraryPageLoadingIndicator() {
  return (
    <Box {...BOX_PROPS}>
      <MrSpinner message={LOADING_MESSAGE} />
    </Box>
  );
}
