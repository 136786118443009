import { useMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { validate } from 'parameter-validator';
import {
  mutation as insertAnalyticsFileMutation,
  buildVariables as buildInsertAnalyticsFileVariables,
} from './insert-analytics-file-mutation-builder';

export default function useInsertAnalyticsFile(options = {}) {
  const [executeMutation, response] = useMutation(insertAnalyticsFileMutation, {
    context: { permission: permissions.updatePipelineAJSBuilder },
    ...options,
  });

  const insertMutation = args => {
    const { settings, identitySyncs, integrations, pipelineId, googleTagSettings } = validate(args, [
      'settings',
      'identitySyncs',
      'integrations',
      'pipelineId',
      'googleTagSettings',
    ]);
    return executeMutation({
      variables: buildInsertAnalyticsFileVariables({
        settings,
        identitySyncs,
        integrations,
        pipelineId,
        googleTagSettings,
      }),
    });
  };

  return [insertMutation, response];
}
