import { PARENT_GTAG_CONTAINER, SHARED_PARENT_GTAG_SYNCS } from './identity-sync-card/components/consts';
import { getSyncFieldName } from './identity-sync-card/components/helpers';

export const handleDelete = (
  modalOpen,
  stagedIdentitySyncs,
  addIdentitySyncs,
  stagedIntegrations,
  setStagedIntegrations,
  removeFields
) => {
  if (modalOpen.type === 'Identity Sync') {
    // handle the case where the modal is opened by a GoogleTagParentContainer
    if (modalOpen.name === 'googleGtag') {
      // collect names from all the syncs in the data
      const gtagSyncNamesToDelete = modalOpen.data.map(sync => sync.name);

      // remove sync names from staged identity syncs but keep a reference to the syncs
      const updatedGtagSyncs = stagedIdentitySyncs.filter(s => {
        return !gtagSyncNamesToDelete.includes(s);
      });

      addIdentitySyncs(updatedGtagSyncs);

      // if updatedStagedIdentitySyncs does not include any of the SHARED_PARENT_GTAG_SYNCS.has(sync.name))
      // remove the GoogleTagParentContainer from staged identity syncs

      if (!updatedGtagSyncs.some(s => SHARED_PARENT_GTAG_SYNCS.has(s.name))) {
        //get first element of modalOpen.data
        const firstElement = modalOpen.data[0];
        const fieldNames = firstElement.parentFields.map(field => getSyncFieldName(PARENT_GTAG_CONTAINER, field.name));
        // delete the fields from the formState
        removeFields(fieldNames);
      }
    } else {
      // remove sync from staged syncs
      const updatedStagedIdentitySyncs = stagedIdentitySyncs.filter(s => {
        return s !== modalOpen.data.name;
      });
      addIdentitySyncs(updatedStagedIdentitySyncs);
    }
  } else if (modalOpen.type === 'Client-Side Integration') {
    // remove integration from staged integrations
    const updatedStagedIntegrations = stagedIntegrations.filter(i => {
      return i !== modalOpen.data.name;
    });
    setStagedIntegrations(updatedStagedIntegrations);
  }
};
