import { useQuerySchemaNames } from 'api';

export default function useValidateUniqueName(defaultValue, disabled) {
  const { schemaNames } = useQuerySchemaNames();

  async function validateUniqueName(name) {
    const shouldValidate = !(name === defaultValue) && !disabled;
    if (!shouldValidate) {
      return true;
    }

    if (schemaNames) {
      return schemaNames.includes(name) ? 'This schema name is already in use' : true;
    }

    return true;
  }

  return validateUniqueName;
}
