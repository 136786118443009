import React from 'react';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import CreatePipelineSchemaLibraryModal from 'app/pipelines/card/PipelineSchemaSection/modals/CreatePipelineSchemaLibraryModal';
import { usePipelineSchemaLibraryTableContext } from 'app/pipelines/card/PipelineSchemaSection/PipelineSchemaLibraryTableContext';

import AddSchemaLibraryButton from './AddSchemaLibraryButton';
import SchemaViolationsLink from './SchemaViolationsLink';

export default function PipelineSchemaTableActions() {
  const { schemaLibraryVersionId } = usePipelineSchemaLibraryTableContext();

  if (schemaLibraryVersionId) {
    return <SchemaViolationsLink />;
  }

  return <ModalLauncher Launcher={AddSchemaLibraryButton} Modal={CreatePipelineSchemaLibraryModal} />;
}
