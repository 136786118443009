import actions from 'api/audit-logs/actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ id, name, description: eventNameDescription }) {
  const action = actions.event_name_created;
  const description = `${name}`;
  const debug_data = {
    id,
    name,
    description: eventNameDescription,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
