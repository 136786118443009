import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@metarouter-private/mr-mui';

export default function ConfirmDeleteMessage({ name }) {
  return (
    <Typography align="left" variant="body1" color="text.secondary">
      Are you sure you want to delete the{' '}
      <Typography fontWeight="700" component="span">
        {name}
      </Typography>{' '}
      Event Schema? This operation can’t be undone
    </Typography>
  );
}

ConfirmDeleteMessage.propTypes = {
  name: PropTypes.string,
};
