import { gql } from '@apollo/client';
import permissions from 'api/Apollo/permissions';
import useQuery from 'api/hooks/useQuery';
import eventName from 'api/event-names/eventName';
import schema from 'api/schemas/schema';
import schemaVersion from 'api/schemas/schemaVersion';

export const GET_EVENT_SCHEMA_OPTIONS = gql`
  query EventSchemaOptions {
    eventNames: event_names {
      ...eventName
    }
    schemas {
      ...schema
      schemaVersions: schema_versions(order_by: { createdAt: desc }) {
        ...schemaVersion
      }
    }
  }
  ${eventName}
  ${schema}
  ${schemaVersion}
`;

export default function useQueryEventSchemaOptions({ variables, ...options } = {}) {
  const { data, loading, isAllowed, error } = useQuery(GET_EVENT_SCHEMA_OPTIONS, {
    variables, // can apply any transforms or default vars here
    context: { permission: permissions.readSchemas },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data,
    loading,
    isAllowed,
    error,
  };
}
