import useLazyQuery from '../hooks/useLazyQuery';
import { buildVariables, GET_SCHEMAS } from './schemas-query-builder';
import permissions from '../Apollo/permissions';

export default function useLazyQuerySchemas({ ...options } = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(GET_SCHEMAS, {
    context: { permission: permissions.readSchemas },
    // Not using the cache so we can dynamically refresh whenever new schema created.
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const getSchemas = pagination => {
    const variables = buildVariables(pagination);
    return executeQuery({ variables });
  };

  return [getSchemas, { ...transformData(data), ...rest }];
}

function transformData(data) {
  if (!data) {
    return {
      data: [],
      pagination: { count: null },
    };
  }

  const { schemas, schemas_aggregate } = data;

  return {
    data: transformSchemas(schemas),
    pagination: { count: schemas_aggregate.aggregate.count },
  };
}

function transformSchemas(schemas) {
  return schemas.map(schema => {
    const assignmentsCount = schema.schemaVersions.reduce((count, version) => {
      return count + version.schemaLibraryEventSchemasAggregate.aggregate.count;
    }, 0);

    return {
      ...schema,
      assignmentsCount,
    };
  });
}
