import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permissions from 'api/Apollo/permissions';
import { SCHEMA_LIBRARY_WITH_VERSIONS_QUERY } from 'api/schema-libraries/useQuerySchemaLibraryWithVersions';
import { mutation, buildVariables } from './update-schema-library-version-mutation-builder';

export default function useUpdateSchemaLibraryVersion(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_library_version_updated');
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    refetchQueries: [SCHEMA_LIBRARY_WITH_VERSIONS_QUERY],
    context: { permission: permissions.updateSchemas },
  });

  const updateSchemaLibraryVersionMutation = async ({ id, status }) => {
    const response = await executeMutation({
      variables: {
        ...buildVariables({
          id,
          status,
        }),
      },
    });

    if (!response.errors) {
      trackWithUser();
    }

    return response;
  };

  return [updateSchemaLibraryVersionMutation, response];
}
