import isAllowedToUpdateSchemas from '@ion/api/schemas/permissions/isAllowedToUpdateSchemas';
import isAllowedToDeleteSchemas from '@ion/api/schemas/permissions/isAllowedToDeleteSchemas';

import { useModalContext } from 'app/shared/contexts/modal';

import MODAL_TYPE from 'app/schema-enforcement/SchemasTab/modals/MODAL_TYPE';

const DISABLED_MESSAGES = {
  SCHEMA_IN_USE: 'To edit, first unassign this schema from all schema libraries',
  NO_EDIT_PERMISSIONS: 'Your account does not have permission to edit schemas',
  NO_DELETE_PERMISSIONS: 'Your account does not have permission to delete schemas',
};

export default function useSchemaRowActionsCellProps(schema) {
  const { openModal } = useModalContext();

  const schemaIsAssigned = schema.assignmentsCount > 0;
  const canEdit = isAllowedToUpdateSchemas();
  const canDelete = isAllowedToDeleteSchemas() && !schemaIsAssigned;

  const editDisabledMessage = DISABLED_MESSAGES.NO_EDIT_PERMISSIONS;
  const deleteDisabledMessage = schemaIsAssigned
    ? DISABLED_MESSAGES.SCHEMA_IN_USE
    : DISABLED_MESSAGES.NO_DELETE_PERMISSIONS;

  const onEdit = () => {
    openModal(MODAL_TYPE.EDIT, { schema });
  };

  const onDelete = () => {
    openModal(MODAL_TYPE.CONFIRM_DELETE, { schema });
  };

  const rowActionsCellProps = {
    canEdit,
    canDelete,
    onEdit,
    onDelete,
    editDisabledMessage,
    deleteDisabledMessage,
  };

  return rowActionsCellProps;
}
