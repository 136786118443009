import React from 'react';
import PropTypes from 'prop-types';

import { FormModal } from '@metarouter-private/mr-mui';

import SchemaLibraryForm from './SchemaLibraryForm';
import useDeploySchemaLibrary from './useDeploySchemaLibrary';

export default function PipelineSchemaLibraryModal(props) {
  const {
    open,
    onClose,
    title,
    TitleIcon,
    submitLabel,
    helperText,
    pipelineSchemaLibraryId,
    schemaLibraryId,
    schemaLibraryVersionId,
  } = props;
  const onSubmit = useDeploySchemaLibrary({ pipelineSchemaLibraryId, onClose });

  const modalProps = {
    open,
    onClose,
    onSubmit,
    title,
    TitleIcon,
    submitLabel,
    helperText,
  };

  return (
    <FormModal {...modalProps}>
      <SchemaLibraryForm schemaLibraryId={schemaLibraryId} schemaLibraryVersionId={schemaLibraryVersionId} />
    </FormModal>
  );
}

PipelineSchemaLibraryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  TitleIcon: PropTypes.elementType.isRequired,
  helperText: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  pipelineSchemaLibraryId: PropTypes.string,
  schemaLibraryId: PropTypes.string,
  schemaLibraryVersionId: PropTypes.string,
};
