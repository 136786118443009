import { isEmpty as _isEmpty, isEqual as _isEqual, omit as _omit } from 'lodash';
import deepEqual from 'deep-equal';

export const diffParameterAndForm = (formStateParameterized, operationData) => {
  // directAssignment
  const noTransforms = !formStateParameterized.transforms || _isEmpty(formStateParameterized?.transforms[0]);
  const directAssignment = operationData.transforms && operationData.transforms[0].directAssignment === null;

  if (noTransforms && directAssignment) {
    return !_isEqual(formStateParameterized, _omit(operationData, 'transforms'));
  }

  return !deepEqual(formStateParameterized, operationData);
};

export default diffParameterAndForm;
