import React from 'react';
import PropTypes from 'prop-types';

import { Edit03Icon, IconButton } from '@metarouter-private/mr-mui';
import isAllowedToUpdateSchemas from '@ion/api/schemas/permissions/isAllowedToUpdateSchemas';

import WithTooltip from 'app/components/WithTooltip';

const TOOLTIP_TITLE = 'To edit, first unassign the Event from any Schema Libraries.';

export default function EditEventButton({ row, onClick }) {
  const hasPermission = isAllowedToUpdateSchemas() && row.assignmentsCount === 0;

  return (
    <WithTooltip hasPermission={hasPermission} title={TOOLTIP_TITLE}>
      <IconButton data-test-id={'editEventButton'} disabled={!hasPermission} onClick={onClick}>
        <Edit03Icon />
      </IconButton>
    </WithTooltip>
  );
}

EditEventButton.propTypes = {
  row: PropTypes.shape({
    assignmentsCount: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};
