import { validate } from 'parameter-validator';

import { useMutation, useTrackWithUser } from '../../index';
import permissions from '../../Apollo/permissions';
import { SCHEMAS_QUERY } from '../schemas-query-builder';
import { SCHEMA_NAMES_QUERY } from 'api/schemas/schema-names-query-builder';

import {
  mutation as removeSchemaMutation,
  buildVariables as buildRemoveSchemaVariables,
} from './remove-schema-mutation-builder';

import {
  mutation as schemaDeletedMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/schema-deleted/audit-log-mutation-builder';

import mergeQueries from '../../Apollo/merge-queries';

export const combinedMutation = mergeQueries('RemoveSchemaAndAudit', [removeSchemaMutation, schemaDeletedMutation]);

export default function useRemoveSchema(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_deleted');
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Schema removed.',
    context: { permission: permissions.deleteSchemas },
    refetchQueries: [SCHEMAS_QUERY, SCHEMA_NAMES_QUERY],
    ...options,
  });

  const removeSchema = args => {
    const { id, name, description } = validate(args, ['id', 'name', 'description']);
    const variables = buildVariables({ id, name, description });
    return executeMutation(variables).then(response => {
      if (!response.errors) {
        trackWithUser({ id, name, description });
      }
      return response;
    });
  };
  return [removeSchema, response];
}

function buildVariables({ id, ...rest }) {
  return {
    variables: {
      ...buildRemoveSchemaVariables({ id }),
      ...buildAuditLogVariables({ id, ...rest }),
    },
  };
}
