import React from 'react';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import { useSchemaLibraryPageContext } from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';
import CreateEventSchemaModal from 'app/schema-enforcement/SchemaLibraryPage/modals/CreateEventSchemaModal';
import NewEventSchemaButton from './NewEventSchemaButton';

export default function EventSchemasTableActions() {
  const { activeVersion } = useSchemaLibraryPageContext();
  const schemaLibraryVersionId = activeVersion.id;

  return (
    <ModalLauncher
      Launcher={NewEventSchemaButton}
      Modal={CreateEventSchemaModal}
      modalProps={{ schemaLibraryVersionId }}
    />
  );
}

EventSchemasTableActions.propTypes = {};
