import { gql } from '@apollo/client';
import schemaVersion from './schemaVersion';
import schema from './schema';
import buildLimitAndOffset from '../pagination/buildLimitAndOffset';

export const SCHEMAS_QUERY = 'Schemas';

export const GET_SCHEMAS = gql`
  query ${SCHEMAS_QUERY}(
    $limit: Int!
    $offset: Int!
  ) {
    schemas_aggregate {
      aggregate {
          count
      }
    }
    schemas(limit: $limit, offset: $offset, order_by: {name: asc}) {
      ...schema
        schemaVersions: schema_versions(order_by: {version: desc}) {
        id
        version
        schemaLibraryEventSchemasAggregate: schema_library_event_schemas_aggregate(where: { schema_library_version: { schema_library: { deletedAt: {_is_null: true}}}}) {
          aggregate {
            count
          }
        }
      }
    }
  }
  ${schemaVersion}
  ${schema}
`;

export function buildVariables(pagination) {
  return {
    ...buildLimitAndOffset(pagination),
  };
}
