import React from 'react';
import PropTypes from 'prop-types';
import { FormTextField, FormFieldContext, Stack } from '@metarouter-private/mr-mui';

export default function SchemaLibraryForm({ name, description }) {
  return (
    <Stack spacing={2}>
      <FormFieldContext name="name" label="Schema Library Name" defaultValue={name} required>
        <FormTextField />
      </FormFieldContext>

      <FormFieldContext name="description" label="Description" defaultValue={description}>
        <FormTextField multiline />
      </FormFieldContext>
    </Stack>
  );
}

SchemaLibraryForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};
