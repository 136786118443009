import useQuery from 'api/hooks/useQuery';
import permissions from 'api/Apollo/permissions';

import { GET_ALL_SCHEMA_LIBRARIES } from './all-schema-libraries-query-builder';

export default function useQueryAllSchemaLibraries({ ...options } = {}) {
  const { data, loading, isAllowed, error } = useQuery(GET_ALL_SCHEMA_LIBRARIES, {
    context: { permission: permissions.readSchemaLibrary },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: transformData(data),
    loading,
    isAllowed,
    error,
  };
}

function transformData(data) {
  if (!data) {
    return [];
  }

  const { schemaLibraries } = data;

  return schemaLibraries;
}
