import useQuery from 'api/hooks/useQuery';
import permissions from 'api/Apollo/permissions';

import { GET_SCHEMA_NAMES } from './schema-names-query-builder';

export default function useQuerySchemaNames({ ...options } = {}) {
  const { data, loading, isAllowed, error, ...rest } = useQuery(GET_SCHEMA_NAMES, {
    context: { permission: permissions.readSchemas },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  return {
    ...transformData(data),
    loading,
    isAllowed,
    error,
    ...rest,
  };
}

function transformData(data) {
  if (!data) {
    return { schemaNames: [] };
  }

  const schemaNames = data.schemas.map(schema => schema.name);

  return { schemaNames };
}
