import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import DeleteEventSchemaButton from './DeleteEventSchemaButton';
import EditEventSchemaButton from './EditEventSchemaButton';

const STACK_PROPS = {
  alignItems: 'center',
  direction: 'row',
};

export default function ActionsCell({ row }) {
  return (
    <Stack {...STACK_PROPS}>
      <EditEventSchemaButton row={row} />
      <DeleteEventSchemaButton row={row} />
    </Stack>
  );
}

ActionsCell.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};
