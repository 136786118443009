import { useEffect } from 'react';

import { useGetPipelineSchemaViolationsDetails } from '@ion/api';

import { useSchemaViolationsPageContext } from 'app/schema-violations/SchemaViolationsPage/SchemaViolationsPageContextProvider';
import { useSchemaViolationsDrawerContext } from 'app/schema-violations/SchemaViolationsPage/SchemaViolationsTableSection/SchemaViolationsDrawerContextProvider';

import schemaViolationsDetailsColumns from './schemaViolationsDetailsColumns';

const STATIC_PROPS = {
  pagination: true,
  columns: schemaViolationsDetailsColumns,
};

// TODO: Replace when we create time select.
const earliestSeen = new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString();

export default function useSchemaViolationsDetailsTable() {
  const { pipeline } = useSchemaViolationsPageContext();
  const {
    drawer: { data: drawerData },
  } = useSchemaViolationsDrawerContext();
  const [getPipelineSchemaViolationsDetails, { loading, data }] = useGetPipelineSchemaViolationsDetails();

  useEffect(() => {
    if (drawerData?.eventName) {
      getPipelineSchemaViolationsDetails({
        earliestSeen,
        versionId: drawerData?.versionId,
        eventName: drawerData.eventName,
        schemaId: drawerData.schemaId,
        clusterId: pipeline.clusterId,
        writekey: pipeline.writekey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline.clusterId, pipeline.writekey, drawerData?.schemaId, drawerData?.eventName]);

  return {
    ...STATIC_PROPS,
    loading,
    data,
  };
}
