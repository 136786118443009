import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@metarouter-private/mr-mui';

export default function ConfirmRemovePipelineSchemaLibraryMessage({ name, version }) {
  return (
    <Typography align="left" variant="body1" color="text.secondary">
      Are you sure you want to remove the{' '}
      <Typography fontWeight="700" component="span">
        {name}
      </Typography>{' '}
      <Typography fontWeight="700" component="span">
        (Version {version})
      </Typography>{' '}
      Schema Library from this Pipeline? This will also update the cluster
    </Typography>
  );
}

ConfirmRemovePipelineSchemaLibraryMessage.propTypes = {
  name: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
};
