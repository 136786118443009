function prepareSchemasForControlApi({ writekey, eventSchemas }) {
  const schemas = eventSchemas.reduce(buildSchemaReducer(writekey), []);
  return schemas;
}

function buildSchemaReducer(writekey) {
  function reduceSchemas(acc, eventSchema) {
    const { schemaVersionId, schemaId, jsonSchema, event } = extractEventSchemaData(eventSchema);

    const existingSchemaVersionEntry = acc.find(trackedSchema => trackedSchema.versionId === schemaVersionId);
    if (existingSchemaVersionEntry) {
      const updatedEventNames = buildUpdatedEventListForSchema(existingSchemaVersionEntry, event);
      existingSchemaVersionEntry.eventNames = updatedEventNames;
      return acc;
    }

    return [
      ...acc,
      {
        writeKey: writekey,
        schemaId,
        versionId: schemaVersionId,
        jsonSchema,
        eventNames: event ? [event.name] : [],
      },
    ];
  }

  return reduceSchemas;
}

function extractEventSchemaData(eventSchema) {
  const { schemaVersion, event } = eventSchema;
  const { id: schemaVersionId, jsonSchema, schemaId } = schemaVersion;

  return {
    event,
    schemaId,
    schemaVersionId,
    jsonSchema,
  };
}

function buildUpdatedEventListForSchema(existingSchemaVersionEntry, event) {
  const isCatchallSchema = !event || !existingSchemaVersionEntry.eventNames.length;
  if (isCatchallSchema) {
    return [];
  }

  return [...existingSchemaVersionEntry.eventNames, event.name];
}

export default prepareSchemasForControlApi;
