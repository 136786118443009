import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

import { useFormContext } from '@metarouter-private/mr-mui';
import { buildSelectOptions } from '@ion/mrdash';
import { useQueryAllSchemaLibraries } from '@ion/api';

import { SCHEMA_LIBRARY_VERSION_STATUS } from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';
import { SCHEMA_LIBRARY_ID } from '../SchemaLibrarySelect';
import { SCHEMA_LIBRARY_VERSION_ID } from './SchemaLibraryVersionSelect';

export default function useSchemaLibraryVersionSelectOptions() {
  const [options, setOptions] = useState([]);
  const { data: schemaLibraries } = useQueryAllSchemaLibraries();
  const { watch, setValue, formState } = useFormContext();
  const schemaLibraryId = watch(SCHEMA_LIBRARY_ID) || '';

  useEffect(() => {
    if (!isEmpty(schemaLibraries) && !isEmpty(schemaLibraryId)) {
      if (formState.isDirty) {
        setValue(SCHEMA_LIBRARY_VERSION_ID, '');
      }

      const libraryVersions = findVersions(schemaLibraries, schemaLibraryId);
      const transformedVersionsForSelect = libraryVersions.map(transformVersionForSelect);

      const selectedOptions = buildSelectOptions({
        items: transformedVersionsForSelect,
        labelKey: 'versionText',
        valueKey: 'id',
        disabledKey: 'disabled',
      });

      setOptions(selectedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaLibraryId, schemaLibraries]);

  return options;
}

function findVersions(schemaLibraries, schemaLibraryId) {
  const selectedLibrary = schemaLibraries.find(({ id }) => id === schemaLibraryId);
  const versions = selectedLibrary.schemaLibraryVersions;

  return versions;
}

function transformVersionForSelect(version) {
  const versionText =
    version.status === SCHEMA_LIBRARY_VERSION_STATUS.DRAFT ? `${version.version} (Draft)` : version.version;

  const transformedVersion = {
    ...version,
    disabled: version.status === SCHEMA_LIBRARY_VERSION_STATUS.DRAFT,
    versionText,
  };

  return transformedVersion;
}
