import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import useSchemasTable from './useSchemasTable';
import schemasTableColumns from './schemasTableColumns';
import SchemasTableActions from './SchemasTableActions';

const RESULTS_PER_PAGE = 10;

export default function SchemasTable() {
  const schemasTable = useSchemasTable();

  return (
    <PageReactDataTable
      columns={schemasTableColumns}
      perPage={RESULTS_PER_PAGE}
      {...schemasTable}
      actions={<SchemasTableActions />}
    />
  );
}
