import pt from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const DEFAULT_DATA_PIPELINE_CARD_CONTEXT = {
  pipeline: null,
  setPipeline: () => {},
};
const PipelineCardContext = createContext(DEFAULT_DATA_PIPELINE_CARD_CONTEXT);

export const PipelineCardContextProvider = ({ children }) => {
  const [pipeline, setPipeline] = useState(null);
  const pipelineLoading = !pipeline;

  return (
    <PipelineCardContext.Provider
      value={{
        pipeline,
        pipelineLoading,
        setPipeline,
      }}
    >
      {children}
    </PipelineCardContext.Provider>
  );
};

export const usePipelineCardContext = () => {
  return useContext(PipelineCardContext);
};

PipelineCardContextProvider.propTypes = {
  children: pt.node.isRequired,
};
