import { buildSelectOptions } from '@ion/mrdash';

export default function buildSchemaVersionSelectOptions({ schemas, selectedSchemaId }) {
  let options = [];

  if (selectedSchemaId) {
    const selectedSchema = schemas.find(schema => schema.id === selectedSchemaId);
    options = buildSelectOptions({ items: selectedSchema.schemaVersions, labelKey: 'version', valueKey: 'id' });
  }

  return options;
}
