/* eslint-disable filename-export/match-default-export */

import React from 'react';
import CodeEditorContainer, { editorPropTypes } from '../code-editor-container';
import BaseAceCodeEditor from './base-ace-code-editor';

export default function AceCodeEditor(props) {
  return <CodeEditorContainer {...props} CodeEditor={BaseAceCodeEditor} />;
}

AceCodeEditor.propTypes = editorPropTypes;
