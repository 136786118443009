import React from 'react';
import { useTrackPage } from '@ion/api';

import ModalContextProvider from 'app/schema-enforcement/SchemaLibrariesTab/ModalContext';
import ConfirmDeleteModal from 'app/schema-enforcement/SchemaLibrariesTab/modals/ConfirmDeleteModal';
import EditSchemaLibraryModal from 'app/schema-enforcement/SchemaLibrariesTab/modals/EditSchemaLibraryModal';
import SchemaLibrariesTable from './SchemaLibrariesTable';

export default function SchemaLibrariesTab() {
  useTrackPage('schema_libraries_tab_viewed');
  return (
    <ModalContextProvider>
      <SchemaLibrariesTable />
      <EditSchemaLibraryModal />
      <ConfirmDeleteModal />
    </ModalContextProvider>
  );
}
