import React from 'react';
import PropTypes from 'prop-types';

import { FormFieldContext } from '@metarouter-private/mr-mui';

import FormCodeEditor from 'app/components/form/FormCodeEditor';

import useUpdateSchemaFieldValue from './useUpdateSchemaFieldValue';
import validateJson from './validateJson';

const SCHEMA_FIELD_BASE_PROPS = {
  label: 'JSON Schema',
  name: 'jsonSchema',
  required: true,
  defaultValue: '// Use JSON Schema json-schema.org' + '\n'.repeat(20 - 1),
};

export default function SchemaField({ versionId }) {
  const loading = useUpdateSchemaFieldValue(versionId);

  return (
    <FormFieldContext required rules={{ validate: validateJson }} {...SCHEMA_FIELD_BASE_PROPS}>
      <FormCodeEditor lang="json" disabled={loading} />
    </FormFieldContext>
  );
}

SchemaField.propTypes = {
  versionId: PropTypes.string,
};
