import React from 'react';
import PropTypes from 'prop-types';

import { MrCodeEditor } from '@ion/components';

import useYamlCodeEditorProps from './useYamlCodeEditorProps';

export default function YamlCodeEditor(props) {
  const yamlCodeEditorProps = useYamlCodeEditorProps(props);
  return <MrCodeEditor {...yamlCodeEditorProps} />;
}

YamlCodeEditor.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  customErrorFn: PropTypes.func,
};
