import React from 'react';
import PropTypes from 'prop-types';
import { Button, PlusCircleIcon } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';
import tooltipTitle from 'app/schema-enforcement/SchemaLibraryPage/EventSchemasTable/tooltipTitle';
import useCanEditVersion from 'app/schema-enforcement/SchemaLibraryPage/hooks/useCanEditVersion';

const LABEL = 'New Event Schema';
const STATIC_BUTTON_PROPS = {
  startIcon: <PlusCircleIcon />,
  variant: 'outlined',
  size: 'large',
};

export default function NewEventSchemaButton({ onClick }) {
  const canAddEventSchema = useCanEditVersion();

  return (
    <WithTooltip hasPermission={canAddEventSchema} title={tooltipTitle}>
      <Button {...STATIC_BUTTON_PROPS} disabled={!canAddEventSchema} onClick={onClick}>
        {LABEL}
      </Button>
    </WithTooltip>
  );
}

NewEventSchemaButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
