import React from 'react';
import PropTypes from 'prop-types';

import { usePlaybookFiltersContext } from './context/playbook-filters-context';
import YamlCodeEditor from 'app/components/YamlCodeEditor';

import useOnlyOneExpanded from './use-only-one-expanded';
import customFiltersError from './customFiltersError';

export default function FiltersEditor({ selectedTransformation }) {
  const { value, filtersPath, setValue } = usePlaybookFiltersContext();
  useOnlyOneExpanded({ selectedTransformation });

  const yamlCodeEditorProps = {
    name: filtersPath,
    value,
    onChange: ({ target: { value: newValue } }) => setValue(newValue),
    customErrorFn: e => customFiltersError(e),
  };

  return <YamlCodeEditor {...yamlCodeEditorProps} />;
}

FiltersEditor.propTypes = {
  selectedTransformation: PropTypes.string,
};
