import React from 'react';

import normalizeEventName from 'app/schema-enforcement/SchemaLibraryPage/shared/normalizeEventName';

import TextCell from 'app/components/data-table/TextCell';
import ActionsCell from './cells/ActionsCell';

export const eventSchemasTableColumns = [
  {
    name: 'Event',
    cell: row => <TextCell text={normalizeEventName(row.eventName?.name)} />,
  },
  {
    name: 'Schema',
    cell: row => <TextCell text={row.schemaVersion.schema.name} />,
  },
  {
    name: 'Schema Version',
    cell: row => <TextCell text={`Version ${row.schemaVersion.version}`} />,
  },
  {
    button: true,
    cell: row => <ActionsCell row={row} />,
  },
];
