import { gql } from '@apollo/client';

import useLazyQuery from 'api/hooks/useLazyQuery';
import permissions from 'api/Apollo/permissions';

const query = gql`
  query SchemaLibraryVersion($schemaLibraryVersionId: uuid!) {
    schemaLibraryVersion: schema_library_versions_by_pk(id: $schemaLibraryVersionId) {
      id
      version
      schemaLibrary: schema_library {
        id
        name
      }
      eventSchemas: schema_library_event_schemas {
        event: event_name {
          name
          id
        }
        schemaVersion: schema_version {
          id
          jsonSchema
          schemaId
        }
      }
    }
  }
`;

export default function useLazyQuerySchemaLibraryVersion(options = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(query, {
    context: { permission: permissions.readSchemas },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const getPipelineSchemas = ({ schemaLibraryVersionId }) => {
    const variables = { schemaLibraryVersionId };

    return executeQuery({ variables });
  };

  return [getPipelineSchemas, { data, ...rest }];
}
