import React from 'react';

import TextCell from 'app/components/data-table/TextCell';
import FormatNumberCell from 'app/components/data-table/FormatNumberCell';
import SchemaRowActionsCell from './SchemaRowActionsCell';

const schemasTableColumns = [
  {
    name: 'Name',
    cell: row => <TextCell text={row.name} />,
  },
  {
    name: 'Latest Version',
    cell: row => <TextCell text={`Version ${row.schemaVersions[0].version}`} />,
  },
  {
    name: 'Description',
    cell: row => <TextCell text={row.description} />,
  },
  {
    name: 'Schema Library Assignments',
    cell: row => <FormatNumberCell number={row.assignmentsCount} />,
  },
  {
    button: true,
    cell: row => <SchemaRowActionsCell schema={row} />,
  },
];

export default schemasTableColumns;
