// togglePipelineVar decides if it needs to show the pipeline variable toggle.
import { TABLE_OVERRIDES, BIG_QUERY_OPTIONS } from 'app/playbook-editor/parameters/editor-panel/parameter/consts';

const togglePipelineVar = (isExpanded, selectedOperation, name) => {
  // Do we need a toggle for line 10 to 19?
  switch (name) {
    case 'S_3':
    case 'GCS':
    case 'COMPRESSION':
    case 'OPT_OUT_SCHEMA_CREATION':
    case 'TLS':
    case 'BACKLOG_COUNT':
    case 'PROFILE_ID':
    case 'BATCH_SIZE':
    case 'PRIVATE_KEY':
    case 'BROKERS':
    case 'AUTHENTICATION (SASL)':
    case 'STAGE':
    case TABLE_OVERRIDES:
    case BIG_QUERY_OPTIONS:
      return false;
    default:
      return isExpanded && selectedOperation === 'params';
  }
};

export default togglePipelineVar;
