const getSyncFieldName = (syncName, fieldName) => {
  return `sync-${syncName}-${fieldName}`;
};

const shouldDisplayField = (displayIf, formState, syncName) => {
  return (
    !displayIf ||
    (formState[getSyncFieldName(syncName, displayIf)] !== undefined &&
      formState[getSyncFieldName(syncName, displayIf)] !== null &&
      formState[getSyncFieldName(syncName, displayIf)]?.value === 'true')
  );
};

export { getSyncFieldName, shouldDisplayField };
