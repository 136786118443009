import React from 'react';

import { Box, Grid } from '@metarouter-private/mr-mui';

import ActionButtons from './ActionButtons';

const PageActions = () => {
  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item xs={3}>
        {/* TODO: Add schema library version select here */}
      </Grid>

      <Grid item xs={6}></Grid>

      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-end">
          <ActionButtons />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PageActions;
