import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

import { useSchemaLibraryPageContext } from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext/SchemaLibraryPageContextProvider';

import { SCHEMA_LIBRARY_VERSION_STATUS } from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';

export default function useSchemaLibraryVersionSelectProps() {
  const { activeVersion, updateActiveVersion, versions } = useSchemaLibraryPageContext();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!isEmpty(versions)) {
      const options = versions.map(transformVersionToOption);
      setOptions(options);
    }
  }, [versions]);

  const onChange = event => {
    const selectedVersionId = event.target.value;
    updateActiveVersion(selectedVersionId);
  };

  const selectProps = {
    options,
    value: activeVersion.id,
    onChange,
  };

  return selectProps;
}

function transformVersionToOption({ id, version, status }) {
  const isDraftVersion = status === SCHEMA_LIBRARY_VERSION_STATUS.DRAFT;

  const label = isDraftVersion ? `Version ${version} (Draft)` : `Version ${version}`;

  const option = {
    value: id,
    label,
  };

  return option;
}
