import React from 'react';

import { ReactDataTable } from '@metarouter-private/mr-mui';

import usePipelineSchemaTableProps from './usePipelineSchemaTableProps';

export default function PipelineSchemaTable() {
  const pipelineSchemaTableProps = usePipelineSchemaTableProps();
  return <ReactDataTable {...pipelineSchemaTableProps} />;
}
