import { gql } from '@apollo/client';

export const mutation = gql`
  mutation InsertDuplicateSchemaLibraryVersion($duplicated_schema_library_version_id: uuid!) {
    newSchemaLibraryVersion: duplicate_schema_library_version(
      args: { duplicated_schema_library_version_id: $duplicated_schema_library_version_id }
    ) {
      id
      version
    }
  }
`;

export function buildVariables({ schemaLibraryVersionId }) {
  return {
    duplicated_schema_library_version_id: schemaLibraryVersionId,
  };
}
