import useLazyQuery from 'api/hooks/useLazyQuery';
import { GET_SCHEMA_VERSION } from './schema-version-query-builder';
import permissions from 'api/Apollo/permissions';

export default function useLazyQuerySchemaVersion({ ...options } = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(GET_SCHEMA_VERSION, {
    context: { permission: permissions.readSchemas },
    ...options,
  });

  const getSchemaVersion = id => {
    return executeQuery({ variables: { id } });
  };

  return [getSchemaVersion, { schemaVersion: data?.schemaVersion, ...rest }];
}
