import { useMutation } from '../../index';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permissions from '../../Apollo/permissions';
import { PIPELINE_SCHEMA_LIBRARY_QUERY_NAME } from 'api/pipelines/schema-library/pipeline-schema-library-query-builder';
import { validate } from 'parameter-validator';

import {
  mutation as removePipelineSchemaLibraryMutation,
  buildVariables as buildPipelineSchemaLibraryVariables,
} from './remove-pipeline-schema-library-mutation-builder';

import {
  mutation as deleteSchemasFromPipelineMutation,
  buildVariables as buildDeleteSchemasFromPipelineVariables,
} from 'api/control/delete-schemas-from-pipeline/delete-schemas-from-pipeline-mutation-builder';

import {
  mutation as schemaLibraryRemovedFromPipelineAuditLogMutation,
  buildVariables as buildSchemaLibraryRemovedFromPipelineVariables,
} from 'api/audit-logs/schema-library-removed-from-pipeline/audit-log-mutation-builder';

import mergeQueries from 'api/Apollo/merge-queries';

export const combinedMutation = mergeQueries('RemovePipelineSchemaLibraryAndAudit', [
  removePipelineSchemaLibraryMutation,
  deleteSchemasFromPipelineMutation,
  schemaLibraryRemovedFromPipelineAuditLogMutation,
]);

export default function useRemovePipelineSchemaLibrary(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_library_removed_from_pipeline');
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Schema Library removed from Pipeline.',
    context: { permission: permissions.writePipelineSchemaLibrary },
    refetchQueries: [PIPELINE_SCHEMA_LIBRARY_QUERY_NAME],
    ...options,
  });

  const removePipelineSchemaLibrary = async args => {
    const validatedArgs = validate(args, ['id', 'schemaLibraryName', 'pipelineName', 'writekey', 'clusterId']);
    const variables = buildVariables(validatedArgs);
    const response = await executeMutation(variables);
    if (!response.errors) {
      trackWithUser();
    }
    return response;
  };
  return [removePipelineSchemaLibrary, response];
}

function buildVariables({ id, schemaLibraryName, pipelineName, writekey, clusterId }) {
  return {
    variables: {
      ...buildPipelineSchemaLibraryVariables({ id }),
      ...buildDeleteSchemasFromPipelineVariables({ writekey, clusterId }),
      ...buildSchemaLibraryRemovedFromPipelineVariables({ id, schemaLibraryName, pipelineName }),
    },
  };
}
