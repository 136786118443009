import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permission from 'api/Apollo/permissions';
import { mutation, buildVariables as buildUpdateEventName } from './update-event-name-mutation-builder';

export default function useUpdateEventName(options = {}) {
  const [trackWithUser] = useTrackWithUser('event_name_updated');
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    context: { permission: permission.updateEventNames },
  });

  const updateEventNameMutation = async ({ id, name, description }) => {
    const response = await executeMutation({
      variables: {
        ...buildUpdateEventName({
          id,
          name,
          description,
        }),
      },
    });

    if (!response.errors) {
      trackWithUser();
    }

    return response;
  };

  return [updateEventNameMutation, response];
}
