import React from 'react';
import PropTypes from 'prop-types';

import { FormModal } from '@metarouter-private/mr-mui';

import buildSubmitHandler from './buildSubmitHandler';
import useEventSchemaFormOptions from './useEventSchemaFormOptions';
import EventSchemaForm from './EventSchemaForm';

export default function EventSchemaFormModal({ eventId, schemaId, schemaVersionId, onSubmit, ...formModalProps }) {
  const { events, schemas } = useEventSchemaFormOptions();
  const handleSubmit = buildSubmitHandler(onSubmit);

  return (
    <FormModal onSubmit={handleSubmit} {...formModalProps}>
      <EventSchemaForm
        events={events}
        schemas={schemas}
        eventId={eventId}
        schemaId={schemaId}
        schemaVersionId={schemaVersionId}
      />
    </FormModal>
  );
}

EventSchemaFormModal.propTypes = {
  eventId: PropTypes.string,
  schemaId: PropTypes.string,
  schemaVersionId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  TitleIcon: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
};
