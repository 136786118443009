import React from 'react';
import PropTypes from 'prop-types';

import { FormTextField, FormFieldContext } from '@metarouter-private/mr-mui';

const DESCRIPTION_FIELD_BASE_PROPS = {
  label: 'Description',
  name: 'description',
};

export default function DescriptionField({ defaultValue }) {
  return (
    <FormFieldContext {...DESCRIPTION_FIELD_BASE_PROPS} defaultValue={defaultValue}>
      <FormTextField />
    </FormFieldContext>
  );
}

DescriptionField.propTypes = {
  defaultValue: PropTypes.string,
};
