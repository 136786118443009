/* eslint-disable filename-export/match-default-export */

import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MrH, MrInputSelectDeprecated } from '@ion/components';
import s from './index.module.scss';
import IntegrationLogo from 'app/components/integrations/integration-logo';
import IdentitySyncCard from './identity-sync-card/identity-sync-card';
import {
  BRING_YOUR_OWN_SYNCS_REQUEST_NAMES,
  CUSTOM_IDENTITY_SYNC,
  SHARED_PARENT_GTAG_SYNCS,
} from './identity-sync-card/components/consts';
import { useStagedIdentitySyncs } from './useStagedIdentitySyncs';
import GoogleTagParentContainer from './identity-sync-card/components/GoogleTagParentContainer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';
import isFeatureEnabled from '../../launch-darkly/is-feature-enabled';

const AnalyticsBuilderSyncs = ({
  formState,
  identitySyncs,
  stagedIdentitySyncsData = [],
  setField,
  validateField,
  validateRequired,
  validateNumber,
  resetError,
  setModalOpen,
}) => {
  const [stagedIdentitySyncs, addIdentitySyncs] = useStagedIdentitySyncs();
  const [stagedGoogleTagParentSyncs, setStagedGoogleTagParentSyncs] = useState([]);
  const featureFlags = useFlags();
  const splitGoogleTagSettings = isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.splitGoogleTagSettings });

  // remove staged options from identity sync dropdowns
  // remove google tag parent syncs from identity sync dropdowns if flag is set
  const identitySyncOptions = identitySyncs.reduce((acc, i) => {
    if (!BRING_YOUR_OWN_SYNCS_REQUEST_NAMES.includes(i.name) && !stagedIdentitySyncs.includes(i.name)) {
      // if splitGoogleTagSettings is false, remove google tag parent syncs from identity sync dropdowns
      if (!splitGoogleTagSettings && SHARED_PARENT_GTAG_SYNCS.has(i.name)) {
        return acc;
      }

      acc.push({
        value: i.name,
        text: (
          <div data-test-id={`${i.name}-integration`}>
            <IntegrationLogo id={i.name} type="dropdownLogo" />
            {i.friendlyName}
          </div>
        ),
      });
    }
    return acc;
  }, []);

  // Add the CUSTOM_IDENTITY_SYNC at the top of the list
  identitySyncOptions.unshift({
    disabled: stagedIdentitySyncs.includes(CUSTOM_IDENTITY_SYNC.REQUEST_NAME),
    value: CUSTOM_IDENTITY_SYNC.REQUEST_NAME,
    text: (
      <div data-test-id={`${CUSTOM_IDENTITY_SYNC.REQUEST_NAME}-integration`}>
        <IntegrationLogo id={CUSTOM_IDENTITY_SYNC.REQUEST_NAME} type="dropdownLogo" />
        {CUSTOM_IDENTITY_SYNC.DESCRIPTION}
      </div>
    ),
  });

  useEffect(() => {
    const newStagedGoogleTagParentSyncs = stagedIdentitySyncsData.filter(sync =>
      SHARED_PARENT_GTAG_SYNCS.has(sync.name)
    );

    setStagedGoogleTagParentSyncs(newStagedGoogleTagParentSyncs);
  }, [stagedIdentitySyncsData]);

  return (
    <section className={s.section}>
      <MrH h="h5" sans styleNames="sans noMargin">
        <div className={s.circle}>3</div> Identity Syncs
      </MrH>
      <p className={s.headerHelperText}>
        Identity Syncs will be performed when the Sync Injector fires, according to the configuration settings you apply
        here. See our documentation for more information on how you can find and utilize the ID payload that each
        Identity Sync produces.
      </p>
      <div className={s.subSection}>
        {stagedIdentitySyncsData.reduce((acc, sync) => {
          if (SHARED_PARENT_GTAG_SYNCS.has(sync.name)) {
            // If a GoogleTagParentContainer is already added, skip adding another
            if (!acc.some(item => item.type === GoogleTagParentContainer)) {
              acc.push(
                <GoogleTagParentContainer
                  formState={formState}
                  key="shared-gtag-parent"
                  resetError={resetError}
                  setField={setField}
                  setModalOpen={setModalOpen}
                  validateField={validateField}
                  validateNumber={validateNumber}
                  validateRequired={validateRequired}
                  stagedSyncs={stagedGoogleTagParentSyncs}
                />
              );
            }
          } else {
            acc.push(
              <IdentitySyncCard
                formState={formState}
                key={sync.name}
                resetError={resetError}
                setField={setField}
                setModalOpen={setModalOpen}
                sync={sync}
                validateField={validateField}
                validateNumber={validateNumber}
                validateRequired={validateRequired}
              />
            );
          }
          return acc;
        }, [])}
        <div className={s.selectContainer}>
          <div className={s.selectTitle}>Add new Identity Sync</div>
          <MrInputSelectDeprecated
            testId="identitySyncs"
            className={s.inputSelect}
            name="identitySyncs"
            options={identitySyncOptions}
            placeholder="Select Identity Syncs..."
            value={stagedIdentitySyncs}
            isSearchable={true}
            onChange={e => {
              // add selected value to list of staged identity syncs
              const newStagedIdentitySyncs = _cloneDeep(stagedIdentitySyncs);
              newStagedIdentitySyncs.push(e.target.value);
              addIdentitySyncs(newStagedIdentitySyncs);
            }}
          />
        </div>
      </div>
    </section>
  );
};

AnalyticsBuilderSyncs.propTypes = {
  formState: pt.object,
  identitySyncs: pt.array,
  stagedIdentitySyncsData: pt.array,
  setField: pt.func,
  validateField: pt.func,
  validateRequired: pt.func,
  validateNumber: pt.func,
  resetError: pt.func,
  setModalOpen: pt.func,
};

export default AnalyticsBuilderSyncs;
