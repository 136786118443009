import React from 'react';
import { useTrackPage } from '@ion/api';

import ModalContextProvider from 'app/schema-enforcement/EventsTab/ModalContext';
import ConfirmDeleteModal from 'app/schema-enforcement/EventsTab/modals/ConfirmDeleteModal';

import EventsTable from './EventsTable';

export default function EventsTab() {
  useTrackPage('event_names_tab_viewed');
  return (
    <ModalContextProvider>
      <EventsTable />
      <ConfirmDeleteModal />
    </ModalContextProvider>
  );
}
