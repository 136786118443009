import React from 'react';
import PropTypes from 'prop-types';

import { PlusCircleIcon } from '@metarouter-private/mr-mui';

import PipelineSchemaLibraryModal from './shared/PipelineSchemaLibraryModal';

const MODAL_CONFIG = {
  title: 'Add Schema Library',
  helperText:
    'Select a Schema Library, specify its version and start tracking event schema violations for your Pipeline. This will be deployed',
  TitleIcon: PlusCircleIcon,
  submitLabel: 'Add Schema Library',
};

export default function CreatePipelineSchemaLibraryModal({ open, onClose }) {
  const modalProps = {
    open,
    onClose,
    ...MODAL_CONFIG,
  };

  return <PipelineSchemaLibraryModal {...modalProps} />;
}

CreatePipelineSchemaLibraryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
