import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import SchemaLibrarySelect from './SchemaLibrarySelect';
import SchemaLibraryVersionSelect from './SchemaLibraryVersionSelect';

export default function SchemaLibraryForm({ schemaLibraryId, schemaLibraryVersionId }) {
  return (
    <Stack spacing={2}>
      <SchemaLibrarySelect schemaLibraryId={schemaLibraryId} />
      <SchemaLibraryVersionSelect schemaLibraryVersionId={schemaLibraryVersionId} />
    </Stack>
  );
}

SchemaLibraryForm.propTypes = {
  schemaLibraryId: PropTypes.string,
  schemaLibraryVersionId: PropTypes.string,
};
