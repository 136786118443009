/**
 * Builds an array of option objects from a given array of items.
 *
 * This function is designed to transform an array of objects into a
 * standardized format with `label`, `value`, and `disabled` properties, which is
 * commonly used in dropdowns, selection inputs, and similar components.
 *
 * @param {Array} items - The array of objects to transform.
 * @param {string} labelKey - The key in each object to use for the `label` property.
 * @param {string} valueKey - The key in each object to use for the `value` property.
 * @param {string} disabledKey - The key in each object to use for the `disabled` property.
 *
 * @returns {Array} - A new array where each item is an object with `label`, `value`, and `disabled` properties.
 * Each `label` is derived from the specified `labelKey`, each `value` corresponds to the specified `valueKey`,
 * and `disabled` is derived from the specified `disabledKey` or defaults to false.
 *
 * @example
 * const items = [
 *   { name: 'Option A', id: 1 },
 *   { name: 'Option B', id: 2, disabled: true }
 * ];
 *
 * const options = buildSelectOptions({
 *   items,
 *   labelKey: 'name',
 *   valueKey: 'id',
 *   disabledKey: 'disabled'
 * });
 * // Output:
 * // [
 * //   { label: 'Option A', value: 1, disabled: false },
 * //   { label: 'Option B', value: 2, disabled: true }
 * // ]
 */
export default function buildSelectOptions({ items, labelKey, valueKey, disabledKey }) {
  return items.map(item => {
    if (!(labelKey in item)) {
      throw new Error(`There is a missing key: ${labelKey}`);
    }
    if (!(valueKey in item)) {
      throw new Error(`There is a missing key: ${valueKey}`);
    }
    return {
      label: item[labelKey].toString(),
      value: item[valueKey].toString(),
      disabled: item[disabledKey] || false,
    };
  });
}
