import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@metarouter-private/mr-mui';

const LABEL = 'Schema Version';

export default function SchemaVersionSelect({ value, options, onChange }) {
  return <Select label={LABEL} value={value} options={options} onChange={onChange} />;
}

SchemaVersionSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
