import { gql } from '@apollo/client';

export const ALL_SCHEMA_LIBRARIES_QUERY = 'AllSchemaLibraries';

export const GET_ALL_SCHEMA_LIBRARIES = gql`
  query ${ALL_SCHEMA_LIBRARIES_QUERY} {
    schemaLibraries: schema_libraries {
      id
      name
      schemaLibraryVersions: schema_library_versions(order_by: { version: desc }) {
        id
        version
        status
      }
    }
  }
`;
