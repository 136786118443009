import { useUpdateEventSchema } from '@ion/api';

import { useModalContext, MODAL_ACTIONS } from 'app/schema-enforcement/SchemaLibraryPage/ModalContext';

export default function useEditEventSchemaModal() {
  const { modalState, closeModal } = useModalContext();
  const { eventSchema, activeModal } = modalState;

  const [updateEventSchema] = useUpdateEventSchema({ onCompleted: closeModal });

  const open = !!(eventSchema && activeModal === MODAL_ACTIONS.EDIT_EVENT_SCHEMA);

  const onSubmit = ({ eventNameId, schemaVersionId }) => {
    updateEventSchema({
      id: eventSchema.id,
      eventNameId,
      schemaVersionId,
    });
  };

  return {
    open,
    eventId: eventSchema?.eventName?.id,
    schemaId: eventSchema?.schemaVersion.schemaId,
    schemaVersionId: eventSchema?.schemaVersion.id,
    onSubmit,
    onClose: closeModal,
  };
}
