import React from 'react';
import { isEmpty } from 'lodash';

import { Select } from '@metarouter-private/mr-mui';

import useSchemaLibraryVersionSelectProps from './useSchemaLibraryVersionSelectProps';

const LABEL = 'Schema Library Version';
const DATA_TEST_ID = 'schemaLibraryVersionSelect';

export default function SchemaLibraryVersionSelect() {
  const selectProps = useSchemaLibraryVersionSelectProps();

  if (isEmpty(selectProps.options)) {
    return null;
  }

  return <Select label={LABEL} data-test-id={DATA_TEST_ID} {...selectProps} />;
}
