import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from '@metarouter-private/mr-mui';
import buildSchemaVersionSelectOptions from './buildSchemaVersionSelectOptions';

const DEFAULT_KEY = 'no-schema-selected';

export default function useSchemaVersionSelect({ schemas, defaultValue, defaultSchemaId }) {
  const { watch, resetField } = useFormContext();
  const [selectedSchemaId, setSelectedSchemaId] = useState(defaultSchemaId);
  const [defaultSchemaVersion, setDefaultSchemaVersion] = useState(defaultValue);

  const schemaWatcher = useCallback(
    (value, { name }) => {
      if (name === 'schema') {
        setSelectedSchemaId(value.schema);
        setDefaultSchemaVersion(null);
        resetField('schemaVersion');
      }
    },
    [resetField]
  );

  useEffect(() => {
    const { unsubscribe } = watch(schemaWatcher, defaultSchemaId);
    return () => unsubscribe();
  }, [watch, schemaWatcher, defaultSchemaId]);

  const options = buildSchemaVersionSelectOptions({ schemas, selectedSchemaId });

  const key = selectedSchemaId ? selectedSchemaId : DEFAULT_KEY;

  return { options, defaultSchemaVersion, disabled: !selectedSchemaId, key };
}
