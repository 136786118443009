// When adding a new permission, it must be also added to the Terraform Local values
// terraform/modules/metarouter/auth0/ui/locals.tf
// You must run `terraform init && terraform apply` to add the permission to Auth0
// Maintain same order as Terraform local values.
const permissions = {
  updateConsentCategory: 'update:consent_category',
  deleteConsentCategory: 'delete:consent_category',
  createConsentCategory: 'create:consent_category',
  readConsentCategory: 'read:consent_category',
  readAuditLogs: 'read:audit_logs',
  createPipeline: 'create:pipeline',
  readPipeline: 'read:pipeline',
  updatePipeline: 'update:pipeline',
  deletePipeline: 'delete:pipeline',
  deployPipeline: 'deploy:pipeline',
  createPipelineIntegration: 'create:pipeline_integration',
  readPipelineIntegration: 'read:pipeline_integration',
  updatePipelineIntegration: 'update:pipeline_integration',
  deletePipelineIntegration: 'delete:pipeline_integration',
  readPipelineAJSBuilder: 'read:pipeline_ajsbuilder',
  updatePipelineAJSBuilder: 'update:pipeline_ajsbuilder',
  writePipelineSchemaLibrary: 'create:schemas',
  createCluster: 'create:cluster',
  readCluster: 'read:cluster',
  updateCluster: 'update:cluster',
  deleteCluster: 'delete:cluster',
  readEvents: 'read:events',
  createIntegration: 'create:integration',
  readIntegration: 'read:integration',
  updateIntegration: 'update:integration',
  deleteIntegration: 'delete:integration',
  downloadIntegration: 'download:integration',
  uploadEndpointSchemas: 'upload:endpoint_schemas',
  createSchemas: 'create:schemas',
  readSchemas: 'read:schemas',
  updateSchemas: 'update:schemas',
  deleteSchemas: 'delete:schemas',
  createEventNames: 'create:schemas',
  readEventNames: 'read:schemas',
  updateEventNames: 'update:schemas',
  deleteEventNames: 'delete:schemas',
  readSchemaLibrary: 'read:schemas',
  writeSchemaLibrary: 'create:schemas',
  writeSchemaLibraryEventSchemas: 'create:schemas',
  uploadPlaybooks: 'upload:playbooks',
  createUsers: 'create:users',
  readUsers: 'read:users',
  updateUsers: 'update:users',
  deleteUsers: 'delete:users',
  readUsersRoles: 'read:users_roles',
  updateUsersRoles: 'update:users_roles',
  readUsersPermissions: 'read:users_permissions',
  updateUsersPermissions: 'update:users_permissions',
  playbookTransformation: 'upload:playbooks',
  readOrganizationMembers: 'read:users',
  overrideOrganization: 'override:organization',
  createAnalyticsJSFile: 'create:analytics_js_file',
  readAnalyticsJSFile: 'read:analytics_js_file',
};

export default permissions;
