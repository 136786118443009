import React from 'react';
import PropTypes from 'prop-types';

import { formValidators, MrInputSelectDeprecated } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
const { validateBoolean } = formValidators;

export default function Tls({ name, defaultValue }) {
  const { validateField, formState, setField } = useFormContext();

  return (
    <MrInputSelectDeprecated
      options={['true', 'false']}
      onBlur={validateField}
      name={name}
      value={formState[name]?.value ?? defaultValue ?? ''}
      onChange={setField}
      errorMsg={formState[name]?.errorMsg}
      validators={[validateBoolean()]}
    />
  );
}

Tls.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
};
