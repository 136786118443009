import { gql } from '@apollo/client';
import schemaLibraryVersion from 'api/schema-library-versions/schemaLibraryVersion';

export const mutation = gql`
  mutation UpdateSchemaLibraryVersion($id: uuid!, $status: schema_library_version_status_enum!) {
    updatedSchemaLibraryVersion: update_schema_library_versions_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      ...schemaLibraryVersion
    }
  }
  ${schemaLibraryVersion}
`;

export function buildVariables({ id, status = 'PUBLISHED' }) {
  return { id, status };
}
