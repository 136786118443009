import React from 'react';
import PropTypes from 'prop-types';

import { Button, PlusCircleIcon } from '@metarouter-private/mr-mui';
import isAllowedToWritePipelineSchemaLibrary from '@ion/api/pipeline-schema-libraries/isAllowedToWritePipelineSchemaLibrary';

const ADD_SCHEMA_LIBRARY_BUTTON_LABEL = 'Add Schema Library';

const DEFAULT_BUTTON_PROPS = {
  variant: 'outlined',
  color: 'secondary',
  startIcon: <PlusCircleIcon />,
};

export default function AddSchemaLibraryButton({ onClick }) {
  const hasPermission = isAllowedToWritePipelineSchemaLibrary();

  return (
    <Button {...DEFAULT_BUTTON_PROPS} onClick={onClick} disabled={!hasPermission}>
      {ADD_SCHEMA_LIBRARY_BUTTON_LABEL}
    </Button>
  );
}

AddSchemaLibraryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
