import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import { mutation, buildVariables } from './update-event-schema-mutation-builder';

export default function useUpdateEventSchema(options = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    context: { permission: permissions.updateSchemas },
  });

  const updateEventSchemaMutation = ({ id, eventNameId, schemaVersionId }) => {
    return executeMutation({
      variables: {
        ...buildVariables({
          id,
          eventNameId,
          schemaVersionId,
        }),
      },
    });
  };

  return [updateEventSchemaMutation, response];
}
