/* eslint-disable filename-export/match-default-export */

import React, { useState } from 'react';
import { components } from 'react-select';

import pt from 'prop-types';

import { MrInputCheckbox } from '../../index';

const CheckBoxOption = ({ getStyles, isDisabled, isFocused, isSelected, label, innerProps, setValue, ...rest }) => {
  const [, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
  };

  const checkBoxProps = {
    value: isSelected,
    onChange: setValue,
    name: 'multi-select',
    ...(isDisabled && { disabled: isDisabled }),
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <MrInputCheckbox {...checkBoxProps} />
      <span>{label}</span>
    </components.Option>
  );
};

CheckBoxOption.propTypes = {
  getStyles: pt.any,
  isDisabled: pt.bool,
  isFocused: pt.bool,
  isSelected: pt.bool,
  value: pt.string,
  label: pt.oneOfType([pt.string, pt.element]),
  innerProps: pt.any,
  setValue: pt.func,
};

export default CheckBoxOption;
