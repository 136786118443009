import { gql } from '@apollo/client';

export const SCHEMA_VERSION_QUERY = 'SchemaVersion';

export const GET_SCHEMA_VERSION = gql`
  query ${SCHEMA_VERSION_QUERY}($id: uuid!) {
    schemaVersion: schema_versions_by_pk(id: $id) {
      id
      version
      jsonSchema
    }
  }
`;
