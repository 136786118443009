import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useQueryPipelineSchemaLibrary } from '@ion/api';

import { usePipelineCardContext } from 'app/pipelines/card/context/pipeline-card-context';

const PipelineSchemaLibraryTableContext = createContext(null);

export default function PipelineSchemaLibraryTableContextProvider({ children }) {
  const { pipeline } = usePipelineCardContext();
  const { data, loading } = useQueryPipelineSchemaLibrary({ pipelineId: pipeline.id });
  const tableData = transformLibraryData(data);
  const schemaLibraryVersionId = data?.schemaLibraryVersion.id;

  return (
    <PipelineSchemaLibraryTableContext.Provider value={{ loading, data: tableData, schemaLibraryVersionId }}>
      {children}
    </PipelineSchemaLibraryTableContext.Provider>
  );
}

function transformLibraryData(pipelineSchemaLibrary) {
  if (!pipelineSchemaLibrary) {
    return [];
  }

  const { id, schemaLibraryVersion } = pipelineSchemaLibrary;
  const { id: schemaLibraryVersionId, version, schemaLibrary } = schemaLibraryVersion;
  const { id: schemaLibraryId, name, description } = schemaLibrary;

  return [
    {
      id,
      name,
      description,
      version,
      schemaLibraryId,
      schemaLibraryVersionId,
    },
  ];
}

export function usePipelineSchemaLibraryTableContext() {
  return useContext(PipelineSchemaLibraryTableContext);
}

PipelineSchemaLibraryTableContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
