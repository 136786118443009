import React from 'react';
import PropTypes from 'prop-types';

import { FormSelect, FormFieldContext } from '@metarouter-private/mr-mui';

import useSchemaLibraryVersionSelectOptions from './useSchemaLibraryVersionSelectOptions';

export const SCHEMA_LIBRARY_VERSION_ID = 'schemaLibraryVersionId';

const SCHEMA_LIBRARY_VERSION_SELECT = {
  name: SCHEMA_LIBRARY_VERSION_ID,
  label: 'Select Version',
  required: true,
};

export default function SchemaLibraryVersionSelect({ schemaLibraryVersionId }) {
  const options = useSchemaLibraryVersionSelectOptions();
  const disabled = !options.length;

  return (
    <FormFieldContext {...SCHEMA_LIBRARY_VERSION_SELECT} disabled={disabled} defaultValue={schemaLibraryVersionId}>
      <FormSelect options={options} />
    </FormFieldContext>
  );
}

SchemaLibraryVersionSelect.propTypes = {
  schemaLibraryVersionId: PropTypes.string,
};
