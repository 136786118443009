import React from 'react';
import { isEmpty } from 'lodash';

import { Typography } from '@metarouter-private/mr-mui';

import useValidateEventSchemaCombination from './useValidateEventSchemaCombination';
import { DUPLICATE_EVENT_SCHEMA_ERROR_NAME } from './constants';

export default function DuplicateEventSchemaError() {
  const { errors } = useValidateEventSchemaCombination();

  if (isEmpty(errors)) {
    return null;
  }

  return <Typography color="error">{errors[DUPLICATE_EVENT_SCHEMA_ERROR_NAME].message}</Typography>;
}
