import { useRemoveEventSchema } from '@ion/api';

import { useModalContext, MODAL_ACTIONS } from 'app/schema-enforcement/SchemaLibraryPage/ModalContext';
import normalizeEventName from 'app/schema-enforcement/SchemaLibraryPage/shared/normalizeEventName';

export default function useConfirmDeleteModal() {
  const {
    modalState: { eventSchema, activeModal },
    closeModal,
  } = useModalContext();
  const [removeEventSchema] = useRemoveEventSchema({ onCompleted: closeModal });
  const showDeleteModal = !!(eventSchema && activeModal === MODAL_ACTIONS.DELETE_EVENT_SCHEMA);

  const normalizedEventSchema = normalizeEventSchema(eventSchema);

  const onConfirm = () => {
    removeEventSchema({
      id: eventSchema.id,
      name: normalizedEventSchema.eventName.name,
      description: normalizedEventSchema.eventName.description,
    });
  };

  return {
    name: normalizedEventSchema?.eventName.name,
    showDeleteModal,
    onConfirm,
    onClose: closeModal,
  };
}

function normalizeEventSchema(eventSchema) {
  if (!eventSchema) {
    return;
  }

  const { eventName } = eventSchema;
  const eventNameValue = normalizeEventName(eventName?.name);
  const eventNameDescription = eventName?.description || '';

  return {
    ...eventSchema,
    eventName: {
      ...eventName,
      name: eventNameValue,
      description: eventNameDescription,
    },
  };
}
