import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import EventSelect from './EventSelect';
import SchemaSelect from './SchemaSelect';
import SchemaVersionSelect from './SchemaVersionSelect';
import DuplicateEventSchemaError from './DuplicateEventSchemaError';

export default function EventSchemaForm({ events, schemas, eventId, schemaId, schemaVersionId }) {
  return (
    <Stack spacing={2}>
      <EventSelect events={events} defaultValue={eventId} />

      <SchemaSelect schemas={schemas} defaultValue={schemaId} />

      <SchemaVersionSelect schemas={schemas} defaultValue={schemaVersionId} defaultSchemaId={schemaId} />

      <DuplicateEventSchemaError />
    </Stack>
  );
}

EventSchemaForm.propTypes = {
  eventId: PropTypes.string,
  schemaId: PropTypes.string,
  schemaVersionId: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  schemas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};
