import { gql } from '@apollo/client';
import integration from '../integrations/integration';
import playbook from '../playbooks/playbook';
import schemaLibraryVersion from 'api/schema-library-versions/schemaLibraryVersion';
import schemaLibrary from 'api/schema-libraries/schemaLibrary';

// TODO: only get the latest revision for both pipelines and playbooks (this query is fetching a huge graph)

export default gql`
  fragment pipeline on pipelines {
    id
    clusterId
    name
    writekey
    createdAt
    pipelineIntegrations: pipeline_integrations(where: { deletedAt: { _is_null: true } }) {
      id
      pipelineId
      integrationId
      stagedRevision: staged_revision {
        id
        playbookId
        pipelineIntegrationId
        pipelineVars
        createdAt
        updatedAt
        readyForDeploy
        stagedForDelete
        playbook {
          ...playbook
          integration {
            ...integration
          }
        }
      }
      pipelineIntegrationConsent: pipeline_integration_consent {
        id
        consentCategory: consent_category {
          id
          code
          name
        }
      }
      integration {
        name
      }
      pipeline {
        name
      }
    }
    cluster {
      id
      name
      url
    }
  }
  ${integration}
  ${playbook}
  ${schemaLibraryVersion}
  ${schemaLibrary}
`;
