import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import useEventSchemasTable from './useEventSchemasTable';
import { eventSchemasTableColumns } from './eventSchemasTableColumns';
import EventSchemasTableActions from './EventSchemasTableActions';
import EventSchemasTableTitle from './EventSchemasTableTitle';

export default function EventSchemasTable() {
  const eventSchemasTable = useEventSchemasTable();

  return (
    <PageReactDataTable
      columns={eventSchemasTableColumns}
      {...eventSchemasTable}
      title={<EventSchemasTableTitle />}
      actions={<EventSchemasTableActions />}
    />
  );
}

EventSchemasTable.propTypes = {};
