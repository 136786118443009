import React from 'react';
import { useRemovePipelineSchemaLibrary } from '@ion/api';
import ConfirmRemovePipelineSchemaLibraryMessage from './ConfirmRemovePipelineSchemaLibraryMessage';
import { usePipelineCardContext } from 'app/pipelines/card/context/pipeline-card-context';

const STATIC_PROPS = {
  title: 'Remove Schema Library from Pipeline',
  confirmLabel: 'Remove',
};

export default function useConfirmRemovePipelineSchemaLibrary({ id, name, version }) {
  const {
    pipeline: { name: pipelineName, writekey, clusterId },
  } = usePipelineCardContext(); // TODO: ND - Change this to pull from PipelineSchemaLibraryTableContext
  const [removePipelineSchemaLibrary] = useRemovePipelineSchemaLibrary();

  const onConfirm = () => {
    removePipelineSchemaLibrary({ id, schemaLibraryName: name, pipelineName, writekey, clusterId });
  };

  return {
    ...STATIC_PROPS,
    message: <ConfirmRemovePipelineSchemaLibraryMessage name={name} version={version} />,
    onConfirm,
  };
}
