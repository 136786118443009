import React from 'react';
import PropTypes from 'prop-types';

import { FormFieldContext, FormSelect } from '@metarouter-private/mr-mui';

import useSchemaSelectOptions from './useSchemaSelectOptions';

export default function SchemaSelect({ schemas, defaultValue }) {
  const options = useSchemaSelectOptions(schemas);

  return (
    <FormFieldContext name="schema" label="Schema" defaultValue={defaultValue} required>
      <FormSelect options={options} data-test-id="schemaSelect" />
    </FormFieldContext>
  );
}

SchemaSelect.propTypes = {
  schemas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultValue: PropTypes.string,
};
