import { gql } from '@apollo/client';

export const mutation = gql`
  mutation RemovePipelineSchemaLibrary(
    $pipeline_schema_libraries_pk_columns: pipeline_schema_libraries_pk_columns_input!
    $pipeline_schema_libraries_set_input: pipeline_schema_libraries_set_input!
  ) {
    update_pipeline_schema_libraries_by_pk(
      pk_columns: $pipeline_schema_libraries_pk_columns
      _set: $pipeline_schema_libraries_set_input
    ) {
      id
    }
  }
`;

export function buildVariables({ id }) {
  return {
    pipeline_schema_libraries_pk_columns: {
      id,
    },
    pipeline_schema_libraries_set_input: {
      deletedAt: 'now()', // Sets to current db time.
    },
  };
}
