import React from 'react';
import PropTypes from 'prop-types';

import { Button, PlusCircleIcon } from '@metarouter-private/mr-mui';

import isAllowedToCreateSchemas from '@ion/api/schemas/permissions/isAllowedToCreateSchemas';

const LABEL = 'New Schema';

export default function NewSchemaButton({ onClick }) {
  const hasPermission = isAllowedToCreateSchemas();

  return (
    <Button startIcon={<PlusCircleIcon />} size="large" disabled={!hasPermission} onClick={onClick}>
      {LABEL}
    </Button>
  );
}

NewSchemaButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
