/* eslint-disable filename-export/match-default-export */
/* disabling rule until refactor of analytics builder */

import React from 'react';
import propTypes from 'prop-types';
import MrBaseInputRadio from './base-input-radio';
import { useContext } from 'react';
import { MrWithTooltip } from '@ion/components';
import { RadioGroupChildrenContext } from './input-radio-group-radios';

export default function MrInputRadio({ value, label, disabled, tooltip, className, secondaryLabel }) {
  const { isSelected, onSelect, name } = useRadioGroupContext();

  const radioInput = (
    <MrBaseInputRadio
      label={label}
      value={value}
      name={`${name}-radio`}
      onSelect={onSelect}
      checked={isSelected(value)}
      disabled={disabled}
      className={className}
    />
  );

  const renderContent = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {radioInput}
      {secondaryLabel && <div style={{ marginLeft: '6px' }}>{secondaryLabel}</div>}
    </div>
  );

  return <div>{tooltip ? <MrWithTooltip message={tooltip}>{renderContent()}</MrWithTooltip> : renderContent()}</div>;
}

MrInputRadio.propTypes = {
  value: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  disabled: propTypes.any,
  tooltip: propTypes.string,
  className: propTypes.string,
  secondaryLabel: propTypes.element,
};

MrInputRadio.displayName = 'MrInputRadio';

function useRadioGroupContext() {
  const context = useContext(RadioGroupChildrenContext);

  if (!context) {
    throw new Error('MrInputRadio must be a child of MrInputRadioGroup');
  }

  return context;
}
