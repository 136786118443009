import { useQueryEventSchemaOptions } from '@ion/api/';

const EMPTY_OPTIONS = {
  events: [],
  schemas: [],
};

export default function useEventSchemaFormOptions() {
  const { data: eventSchemaOptions } = useQueryEventSchemaOptions();

  if (!eventSchemaOptions) {
    return EMPTY_OPTIONS;
  }

  const options = {
    events: eventSchemaOptions.eventNames,
    schemas: eventSchemaOptions.schemas,
  };

  return options;
}
