import React from 'react';

import TextCell from 'app/components/data-table/TextCell';
import FormatNumberCell from 'app/components/data-table/FormatNumberCell';
import ActionsCell from './cells/ActionsCell';

export const eventsTableColumns = [
  {
    name: 'Event',
    cell: row => <TextCell text={row.name} />,
  },
  {
    name: 'Description',
    cell: row => <TextCell text={row.description} />,
  },
  {
    name: 'Schema Library Assignments',
    cell: row => <FormatNumberCell number={row.assignmentsCount} />,
  },
  {
    button: true,
    cell: row => <ActionsCell row={row} />,
  },
];
