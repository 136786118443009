import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permissions from 'api/Apollo/permissions';
import { mutation, buildVariables } from './update-schema-library-mutation-builder';

export default function useUpdateSchemaLibrary(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_library_updated');
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    context: { permission: permissions.updateSchemas },
  });

  const updateSchemaLibraryMutation = async ({ id, name, description }) => {
    const response = await executeMutation({
      variables: {
        ...buildVariables({
          id,
          name,
          description,
        }),
      },
    });

    if (!response.errors) {
      trackWithUser();
    }
  };

  return [updateSchemaLibraryMutation, response];
}
