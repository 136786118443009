import React from 'react';

import { useRemoveSchema } from '@ion/api';

import { useModalContext } from 'app/shared/contexts/modal';

import ConfirmDeleteMessage from './ConfirmDeleteMessage';
import MODAL_TYPE from 'app/schema-enforcement/SchemasTab/modals/MODAL_TYPE';

export default function useConfirmDeleteModalProps() {
  const { activeModal, modalData, closeModal } = useModalContext();
  const [removeSchema] = useRemoveSchema({ onCompleted: closeModal });
  const open = Boolean(activeModal === MODAL_TYPE.CONFIRM_DELETE && modalData);

  const onConfirm = () => {
    removeSchema({
      id: modalData.schema.id,
      name: modalData.schema.name,
      description: modalData.schema.description,
    });
  };

  const message = <ConfirmDeleteMessage name={modalData?.schema?.name} />;

  return {
    open,
    onClose: closeModal,
    title: `Delete ${modalData?.schema?.name}`,
    onConfirm,
    message,
  };
}
