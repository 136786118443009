import isAllowedToCreateSchemas from '@ion/api/schemas/permissions/isAllowedToCreateSchemas';

import {
  useSchemaLibraryPageContext,
  SCHEMA_LIBRARY_VERSION_STATUS,
} from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';

export default function useCanEditVersion() {
  const { activeVersion } = useSchemaLibraryPageContext();
  const { status } = activeVersion;

  return isAllowedToCreateSchemas() && status === SCHEMA_LIBRARY_VERSION_STATUS.DRAFT;
}
