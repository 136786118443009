import React from 'react';

import { ReactDataTable } from '@metarouter-private/mr-mui';

import useSchemaViolationsTableProps from './useSchemaViolationsTableProps';

export default function SchemaViolationsTable() {
  const violationsTableProps = useSchemaViolationsTableProps();
  return <ReactDataTable {...violationsTableProps} />;
}
