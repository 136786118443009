import { useState, useEffect } from 'react';

import { useUpdateSchema } from '@ion/api';

import { useModalContext } from 'app/shared/contexts/modal';

import MODAL_TYPE from 'app/schema-enforcement/SchemasTab/modals/MODAL_TYPE';

export default function useEditSchemaModalProps() {
  const { activeModal, modalData, closeModal } = useModalContext();
  const [createNewSchemaVersion] = useUpdateSchema({ onCompleted: closeModal });
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const [versionOptions, setVersionOptions] = useState([]);
  const [nextVersionNumber, setNextVersionNumber] = useState(null);

  const open = Boolean(activeModal === MODAL_TYPE.EDIT && modalData);

  useEffect(() => {
    if (open) {
      const { schema } = modalData;
      const { schemaVersions } = schema;

      const versionOptions = schemaVersions.map(buildVersionOption);
      setVersionOptions(versionOptions);
      setSelectedVersionId(schemaVersions[0].id);
      setNextVersionNumber(schemaVersions[0].version + 1);
    }
  }, [open, modalData]);

  const onSubmit = ({ name, description, jsonSchema }) => {
    const trimmedSchema = jsonSchema.trim();

    createNewSchemaVersion({
      name,
      description,
      jsonSchema: trimmedSchema,
      schemaId: modalData.schema.id,
      version: nextVersionNumber,
    });
  };

  const onVersionChange = event => {
    const selectedVersion = event.target.value;
    setSelectedVersionId(selectedVersion);
  };

  const modalProps = {
    open,
    onClose: closeModal,
    onSubmit,
    onVersionChange,
    versionOptions,
    selectedVersion: selectedVersionId,
    name: modalData?.schema?.name,
    nameDisabled: Boolean(modalData?.schema?.assignmentsCount),
    description: modalData?.schema?.description,
  };

  return modalProps;
}

function buildVersionOption(version) {
  return {
    value: version.id,
    label: `Version ${version.version}`,
  };
}
