import { isNil } from 'lodash';
import permissions from 'api/Apollo/permissions';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import { upsertPipelineSchemaLibraryMutation } from 'api/pipeline-schema-libraries/upsert/upsert-pipeline-schema-library-mutation-builder';
import { schemaLibraryAddedMutation } from 'api/audit-logs/schema-library-added-to-pipeline/audit-log-mutation-builder';
import { GET_PIPELINE_SCHEMA_LIBRARY } from 'api/pipelines/schema-library/pipeline-schema-library-query-builder';
import { useControlProxy } from 'api/control/useControlProxy';
import { METHOD, PATH } from 'api/control/control-api';

import { useUserContext } from '@ion/user';

import extractPipelineSchemaLibraryData from './extractPipelineSchemaLibraryData';
import buildAdditionalVariables from './buildAdditionalVariables';
import prepareSchemasForControlApi from './prepareSchemasForControlApi';

const ADDED_MSG = 'Schema Library added to Pipeline.';
const UPDATED_MSG = 'Schema Library updated on Pipeline.';

function useDeployPipelineSchemas({ pipelineSchemaLibraryId, pipeline, ...options }) {
  const [trackWithUser] = useTrackWithUser('schema_library_deployed_to_pipeline');
  const { user } = useUserContext();

  const successMsg = isNil(pipelineSchemaLibraryId) ? ADDED_MSG : UPDATED_MSG;

  const [executeMutation, response] = useControlProxy({
    successMsg,
    permission: permissions.createSchemas,
    additionalQueries: [upsertPipelineSchemaLibraryMutation, schemaLibraryAddedMutation],
    refetchQueries: [GET_PIPELINE_SCHEMA_LIBRARY],
    ...options,
  });

  async function deployPipelineSchemas({ schemaLibraryVersion }) {
    const { pipelineId, pipelineName, writekey, clusterId, schemaLibraryVersionId, schemaLibraryName, eventSchemas } =
      extractPipelineSchemaLibraryData({ pipeline, schemaLibraryVersion });

    const controlApiSchemas = prepareSchemasForControlApi({ writekey, eventSchemas });
    const additionalVariables = buildAdditionalVariables({
      pipelineSchemaLibraryId,
      pipelineId,
      pipelineName,
      schemaLibraryVersionId,
      schemaLibraryName,
    });

    const mutationResponse = await executeMutation({
      path: PATH.PipelineSchemaLibrary,
      method: METHOD.POST,
      body: {
        library: {
          writeKey: writekey,
          author: user.email,
          schemas: controlApiSchemas,
        },
      },
      clusterId,
      writekey,
      additionalVariables,
    });

    if (!mutationResponse.errors) {
      trackWithUser({
        clusterId,
        pipelineWritekey: writekey,
        pipelineName,
        schemaLibraryName,
        schemaLibraryVersionId,
      });
    }

    return mutationResponse;
  }

  return [deployPipelineSchemas, response];
}

export default useDeployPipelineSchemas;
