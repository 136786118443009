import React from 'react';
import PropTypes from 'prop-types';

import { FormFieldContext, FormSelect } from '@metarouter-private/mr-mui';

import useSchemaVersionSelect from './useSchemaVersionSelect';

export default function SchemaVersionSelect({ schemas, defaultValue, defaultSchemaId }) {
  const { options, defaultSchemaVersion, disabled, key } = useSchemaVersionSelect({
    schemas,
    defaultSchemaId,
    defaultValue,
  });

  return (
    <FormFieldContext
      key={key}
      name="schemaVersion"
      label="Schema Version"
      defaultValue={defaultSchemaVersion}
      disabled={disabled}
      required
    >
      <FormSelect options={options} data-test-id="schemaVersionSelect" />
    </FormFieldContext>
  );
}

SchemaVersionSelect.propTypes = {
  schemas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      schemaVersions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          version: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  defaultValue: PropTypes.string,
  defaultSchemaId: PropTypes.string,
};
