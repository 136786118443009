import { ALL_EVENTS_VALUE } from 'app/schema-enforcement/SchemaLibraryPage/shared/constants';

function getEventId(event) {
  return event?.id || ALL_EVENTS_VALUE;
}
export default function createSchemaEventMap(data) {
  const { schemaLibraryVersion = {} } = data;
  const { eventSchemas = [] } = schemaLibraryVersion;

  return eventSchemas.reduce((schemaEventMap, { schemaVersion: { id: schemaVersionId }, event }) => {
    const eventId = getEventId(event);

    // Initialize a new Set if schemaVersionId doesn't yet exist
    if (!schemaEventMap[schemaVersionId]) {
      schemaEventMap[schemaVersionId] = new Set();
    }

    // Add the eventId to the Set
    schemaEventMap[schemaVersionId].add(eventId);

    return schemaEventMap;
  }, {});
}
