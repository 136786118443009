import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ id, pipelineName, schemaLibraryName }) {
  const action = actions.schema_library_removed_from_pipeline;
  const description = `${schemaLibraryName ?? '-'} removed from ${pipelineName ?? '-'}`;
  const debug_data = {
    id,
    pipelineName,
    schemaLibraryName,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
