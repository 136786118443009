import React from 'react';

import { Grid, Stack } from '@metarouter-private/mr-mui';

import { useSchemaLibraryPageContext } from './SchemaLibraryPageContext';
import SchemaLibraryPageLoadingIndicator from './SchemaLibraryPageLoadingIndicator';
import PageActions from './PageActions';
import SchemaLibraryVersionSelect from './PageActions/SchemaLibraryVersionSelect';
import ModalContextProvider from 'app/schema-enforcement/SchemaLibraryPage/ModalContext';
import ConfirmDeleteModal from 'app/schema-enforcement/SchemaLibraryPage/modals/ConfirmDeleteModal';
import EditEventSchemaModal from 'app/schema-enforcement/SchemaLibraryPage/modals/EditEventSchemaModal';
import EventSchemasTable from './EventSchemasTable';

export default function SchemaLibraryMain() {
  const { loading, activeVersion } = useSchemaLibraryPageContext();

  const showLoadingIndicator = loading || !activeVersion;
  if (showLoadingIndicator) {
    return <SchemaLibraryPageLoadingIndicator />;
  }

  return (
    <ModalContextProvider>
      <Stack spacing={2}>
        <Grid container>
          <Grid item xs={2}>
            <SchemaLibraryVersionSelect />
          </Grid>
          <Grid item xs={10}>
            <PageActions />
          </Grid>
        </Grid>

        <EventSchemasTable />
      </Stack>

      <EditEventSchemaModal />
      <ConfirmDeleteModal />
    </ModalContextProvider>
  );
}
