import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import { useHistory, Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from 'app/constants';
import { omit as _omit, isEmpty as _isEmpty } from 'lodash';

import { useQueryClientSideIntegrations, useQueryIdentitySyncs } from '@ion/api';

import {
  MrAppMain,
  generateId,
  MrButton,
  MrCard,
  MrForm,
  MrH,
  useForm,
  formValidators,
  MrBack,
  MrModal,
  MrSpinner,
} from '@ion/components';
import ConfirmDelete from 'app/components/confirm-delete/confirm-delete';
import { useBuildAjsFile, useQueryAnalyticsFile, useInsertAnalyticsFile } from '@ion/api';

import AnalyticsBuilderResults from './analytics-builder-results';
import AnalyticsBuilderSettings from './analytics-builder-settings';
import AnalyticsBuilderSyncs from './analytics-builder-syncs';
import AnalyticsBuilderIntegrations from './analytics-builder-integrations';
import AnalyticsBuilderCrossDomain from './analytics-builder-cross-domain';

import s from './index.module.scss';
import createAjsBuilderFile from './create-ajs-builder-file';
import populateAjsBuilderForm from './populate-ajs-builder-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../../launch-darkly/is-feature-enabled';
import FieldMissingWarning from './field-missing-warning';
import { useConsentCategoriesContext } from '../../context/consent-categories-context';
import { CONSENT_CATEGORY_FLAGS } from '../../context/gated-consent-categories-context';
import { BUILDER_SERVER_STORAGE_TYPE } from './analytics-builder-advanced-settings';
import { CUSTOM_IDENTITY_SYNC } from './identity-sync-card/components/consts';
import { useStagedIdentitySyncs } from './useStagedIdentitySyncs';
import { useLocation } from 'react-router-dom';
import { HOSTING_TYPES } from './analytics-builder-file-hosting';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';
import { handleDelete } from './handleDelete';
import transformGoogleTagSettingsForDb from './transformGoogleTagSettingsForDb';

const AnalyticsBuilder = ({ name, writekey, clusterName }) => {
  const history = useHistory();
  const {
    location: {
      state: { pipelineId, clusterId },
    },
  } = history;
  const featureFlags = useFlags();
  const { data: analyticsFileData, loading: loadingAnalyticsFile } = useQueryAnalyticsFile({
    variables: { pipelineId: pipelineId },
  });
  const { pathname } = useLocation();

  const [insertAnalyticsFile, { isAllowed: updatePipelineAJSBuilderPermission }] = useInsertAnalyticsFile();

  const { data: integrations, loading: loadingIntegrations } = useQueryClientSideIntegrations();
  const { data: identitySyncs, loading: loadingSyncs } = useQueryIdentitySyncs();
  const { consentCategories } = useConsentCategoriesContext();
  const formId = generateId();
  const { formState, validateField, resetError, submitForm, setField, removeFields } = useForm(formId);
  const { validateRequired, validateUrl, validateNumber, validateNoPath } = formValidators;

  // if the file is hosted on the MR Cluster, we want to show a different success message on upload
  const [build] = useBuildAjsFile({
    successMsg:
      formState?.fileHostingType?.value === HOSTING_TYPES.self
        ? `Analytics.js for ${name} has been successfullly built.`
        : undefined,
  });

  const [stagedIntegrations, setStagedIntegrations] = useState([]);
  const [stagedIdentitySyncs, addIdentitySyncs] = useStagedIdentitySyncs();
  const [resultsData, setResultsData] = useState({});
  const [fileDownloading, setFileDownloading] = useState(false);
  const [generatedFile, setGeneratedFile] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [builderErrorModalOpen, setBuilderErrorModalOpen] = useState(false);
  const [builderErrorMessage, setBuilderErrorMessage] = useState('');
  const [analyticsSnippet, setAnalyticsSnippet] = useState('');
  const [formDataFilled, setFormDataFilled] = useState(false);
  const [isValidForm, setIsValidForm] = useState(null);
  const [currentClusterName] = useState(clusterName);

  useEffect(() => {
    if (!loadingIntegrations && !loadingSyncs && !loadingAnalyticsFile && !formDataFilled) {
      // set staged integrations and sync settings from saved analytics file
      if (analyticsFileData?.integrations) {
        setStagedIntegrations(analyticsFileData?.integrations.map(i => i.name));
      }
      if (!_isEmpty(analyticsFileData?.identitySyncs)) {
        addIdentitySyncs(analyticsFileData?.identitySyncs.map(i => i.name).filter(i => i !== 'crossDomain'));
      }

      // populate builder form with saved analytics file data
      populateAjsBuilderForm(analyticsFileData, setField);

      setFormDataFilled(true);
    }
  }, [
    loadingIntegrations,
    loadingSyncs,
    loadingAnalyticsFile,
    formDataFilled,
    analyticsFileData,
    addIdentitySyncs,
    setField,
  ]);

  let stagedIdentitySyncsData = [];
  let stagedIntegrationsData = [];

  // generate UI cards for staged identity syncs and integrations
  stagedIdentitySyncsData = stagedIdentitySyncs
    ?.map(sync => {
      // Creates the card for CUSTOM_IDENTITY_SYNC, this does not come from the API
      if (sync === CUSTOM_IDENTITY_SYNC.REQUEST_NAME) {
        return { friendlyName: CUSTOM_IDENTITY_SYNC.DESCRIPTION, name: CUSTOM_IDENTITY_SYNC.REQUEST_NAME, fields: [] };
      }
      return identitySyncs.find(s => s.name === sync);
    })
    .filter(s => s !== undefined);

  stagedIntegrationsData = stagedIntegrations
    ?.map(integration => {
      return integrations.find(i => i.name === integration);
    })
    .filter(i => i !== undefined);

  const onFormSubmit = () => {
    const { data, isValid } = submitForm();

    if (isValid) {
      setIsValidForm(true);
      try {
        const crossDomainSettings = analyticsFileData?.identitySyncs?.find(
          sync => sync.name === 'crossDomain'
        )?.settings;

        const { analyticsSnippet, settingsTemplate } = createAjsBuilderFile({
          stagedIdentitySyncsData,
          stagedIntegrationsData,
          data,
          writekey,
          crossDomainSettings,
          optimizeBuild: isFeatureEnabled({ featureFlags, flagName: 'ajsOptimizeFileBuild' }),
          consentCategories,
          queueTasks: isFeatureEnabled({ featureFlags, flagName: 'builderServerQueueTasks' }),
          hasConsentModule: isFeatureEnabled({ featureFlags, flagName: CONSENT_CATEGORY_FLAGS.ENABLED_ORGS }),
          pipelineId,
          isUploadToClusterEnabled: isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.uploadAjsFileToCluster }),
        });
        setAnalyticsSnippet(analyticsSnippet);
        setResultsData(settingsTemplate);

        setFileDownloading(true);
        const config = {
          settings: settingsTemplate,
          identitySyncs: settingsTemplate.syncInjectorSettings.syncs,
          integrations: settingsTemplate.integrations,
          pipelineId,
          googleTagSettings: transformGoogleTagSettingsForDb(settingsTemplate.syncInjectorSettings.googleGtagSettings),
        };
        // store/update generated file settings in database for future access
        insertAnalyticsFile(config)
          .then(() => {
            const buildOptions = {
              settingsJson: _omit(settingsTemplate, 'compressFile'),
              pipelineId,
              pipelineName: name,
            };
            if (buildOptions?.settingsJson?.syncInjectorSettings?.storage?.type) {
              // The builder server uses a different storage type than the client 😑 so we need to convert it before we send the request to the builder server
              buildOptions.settingsJson.syncInjectorSettings.storage.type =
                BUILDER_SERVER_STORAGE_TYPE[buildOptions.settingsJson.syncInjectorSettings.storage.type];
            }
            build(buildOptions)
              .then(({ data: { build_ajs_file = {} } = {} }) => {
                const status = build_ajs_file?.status;
                if (status !== 200) {
                  const errorMessage =
                    build_ajs_file?.data === undefined ? 'Server error, file could not be build' : build_ajs_file.data;
                  setBuilderErrorModalOpen(true);
                  setBuilderErrorMessage(errorMessage);
                  setFileDownloading(false);
                } else {
                  setGeneratedFile(build_ajs_file?.data);
                  history.push(`${routes.analyticsJSBuilder}/results`, { pipelineId, name, writekey, clusterId });
                }
              })
              .catch(() => {
                setFileDownloading(false);
              });
          })
          .catch(() => {
            setFileDownloading(false);
          });
      } catch (e) {
        const errorMessage = e?.message === undefined ? 'Failed to generate settings for file' : e.message;
        setFileDownloading(false);
        setBuilderErrorModalOpen(true);
        setBuilderErrorMessage(errorMessage);
      }
    } else {
      setIsValidForm(false);
    }
  };

  let { path } = useRouteMatch();

  return (
    <>
      <MrAppMain
        styleNames="form"
        loading={loadingIntegrations || loadingSyncs}
        header={<MrBack to={routes.pipelines} />}
      >
        <MrCard className={s.formCard}>
          <div className={s.title}>
            <MrH h="h3" sans styleNames="noMargin">
              {pathname.endsWith('results') ? 'Analytics.js Summary' : 'Edit Analytics.js'}
            </MrH>
          </div>
          <Switch>
            <Route exact path={path}>
              <MrForm onSubmit={onFormSubmit} id={formId} fullWidth>
                <AnalyticsBuilderSettings
                  writekey={writekey}
                  formState={formState}
                  setField={setField}
                  validateField={validateField}
                  validateRequired={validateRequired}
                  validateUrl={validateUrl}
                  validateNoPath={validateNoPath}
                  resetError={resetError}
                />

                <AnalyticsBuilderSyncs
                  formState={formState}
                  identitySyncs={identitySyncs}
                  stagedIdentitySyncsData={stagedIdentitySyncsData}
                  setField={setField}
                  validateField={validateField}
                  validateRequired={validateRequired}
                  validateNumber={validateNumber}
                  resetError={resetError}
                  setModalOpen={setModalOpen}
                />

                <AnalyticsBuilderCrossDomain
                  formState={formState}
                  setField={setField}
                  validateField={validateField}
                  resetError={resetError}
                />

                <AnalyticsBuilderIntegrations
                  formState={formState}
                  integrations={integrations}
                  stagedIntegrations={stagedIntegrations}
                  stagedIntegrationsData={stagedIntegrationsData}
                  setField={setField}
                  validateField={validateField}
                  validateRequired={validateRequired}
                  validateNumber={validateNumber}
                  resetError={resetError}
                  setModalOpen={setModalOpen}
                  setStagedIntegrations={setStagedIntegrations}
                />

                <footer className={s.footer}>
                  <div className={s.buttonContainer}>
                    {fileDownloading && (
                      <MrSpinner
                        className={s.spinner}
                        textPosition="right"
                        size="md"
                        message="Building Analytics.js file..."
                      />
                    )}
                    {isValidForm === false && (
                      <FieldMissingWarning
                        formState={formState}
                        integrations={integrations}
                        identitySyncs={identitySyncs}
                      />
                    )}
                    <MrButton
                      className={s.button}
                      testId="buildFile"
                      text="Save and Build File"
                      disabled={!updatePipelineAJSBuilderPermission || fileDownloading}
                    />
                  </div>
                </footer>
              </MrForm>
            </Route>

            <Route path={`${path}/results`}>
              <AnalyticsBuilderResults
                writekey={writekey}
                resultsData={resultsData}
                analyticsSnippet={analyticsSnippet}
                generatedFile={generatedFile}
                pipelineName={name}
                clusterName={currentClusterName}
              />
            </Route>
          </Switch>
        </MrCard>
      </MrAppMain>

      {builderErrorModalOpen && (
        <MrModal
          closeModal={() => {
            setBuilderErrorModalOpen(false);
            setBuilderErrorMessage('');
          }}
          styleNames="basic"
        >
          <div className={s.header}>
            <MrH h="h3" sans styleNames="noMargin">
              Error
            </MrH>
          </div>
          <div className={s.content}>{builderErrorMessage}</div>
        </MrModal>
      )}

      {modalOpen && (
        <ConfirmDelete
          deleteFn={() =>
            handleDelete(
              modalOpen,
              stagedIdentitySyncs,
              addIdentitySyncs,
              stagedIntegrations,
              setStagedIntegrations,
              removeFields
            )
          }
          closeModal={() => {
            setModalOpen(false);
          }}
        >
          <div className={s.header}>
            <MrH h="h2" styleNames="sans noMargin">
              Remove {modalOpen.data.friendlyName} from this bundle?
            </MrH>
          </div>
          <div className={s.content}>
            <p>
              By removing this {modalOpen.type} from the analytics.js file you will prevent it from triggering on a page
              load.
            </p>
          </div>
        </ConfirmDelete>
      )}
    </>
  );
};

AnalyticsBuilder.propTypes = {
  writekey: pt.string,
  name: pt.string,
  clusterName: pt.string,
};

export default AnalyticsBuilder;
