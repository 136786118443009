import { gql } from '@apollo/client';

export const SCHEMA_NAMES_QUERY = 'SchemaNames';

export const GET_SCHEMA_NAMES = gql`
  query ${SCHEMA_NAMES_QUERY} {
    schemas {
      name
    }
  }
`;
