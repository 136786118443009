import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import useSchemaLibraryData from './useSchemaLibraryData';

const SchemaLibraryPageContext = createContext(null);

export default function SchemaLibraryPageContextProvider({ children }) {
  const { loading, schemaLibraryId, libraryName, activeVersion, updateActiveVersion, versions, containsDraftVersion } =
    useSchemaLibraryData();
  const [isPublishable, setIsPublishable] = useState(false);

  return (
    <SchemaLibraryPageContext.Provider
      value={{
        loading,
        schemaLibraryId,
        libraryName,
        activeVersion,
        updateActiveVersion,
        versions,
        isPublishable,
        setIsPublishable,
        containsDraftVersion,
      }}
    >
      {children}
    </SchemaLibraryPageContext.Provider>
  );
}

export function useSchemaLibraryPageContext() {
  return useContext(SchemaLibraryPageContext);
}

SchemaLibraryPageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
