import React from 'react';

import { ConfirmDelete } from '@metarouter-private/mr-mui';

import useConfirmDeleteModalProps from './useConfirmDeleteModalProps';

export default function ConfirmDeleteSchemaModal() {
  const { open, onClose, onConfirm, title, message } = useConfirmDeleteModalProps();

  return <ConfirmDelete open={open} onClose={onClose} onConfirm={onConfirm} title={title} message={message} />;
}
