import React from 'react';
import PropTypes from 'prop-types';

import { PlusCircleIcon } from '@metarouter-private/mr-mui';

import { useCreateEventSchema } from './useCreateEventSchema';

import EventSchemaFormModal from 'app/schema-enforcement/SchemaLibraryPage/modals/shared/EventSchemaFormModal';

const MODAL_CONFIG = {
  title: 'New Event Schema',
  TitleIcon: PlusCircleIcon,
  submitLabel: 'Create Event Schema',
};

export default function CreateEventSchemaModal({ schemaLibraryVersionId, open, onClose }) {
  const onSubmit = useCreateEventSchema(schemaLibraryVersionId, onClose);

  return <EventSchemaFormModal open={open} onSubmit={onSubmit} onClose={onClose} {...MODAL_CONFIG} />;
}

CreateEventSchemaModal.propTypes = {
  schemaLibraryVersionId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
