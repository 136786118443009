import pt from 'prop-types';
import React from 'react';

import { formValidators, MrInputRadio, MrInputRadioGroup, MrInputText } from '@ion/components';
import FeatureGate from '@ion/app/src/launch-darkly/gates/feature-gate';

import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';

const { validatePattern } = formValidators;

export const COOKIE_INT_VALUE = 1;
export const INDEXED_DB_INT_VALUE = 2;
export const TLD_COOKIE_INT_VALUE = 4;

export const STORAGE_TYPE = {
  COOKIES: 'COOKIES',
  INDEXED_DB: 'INDEXED_DB',
  TLD_COOKIES: 'TLD_COOKIES',
};

export const READABLE_STORAGE_TYPE = {
  [COOKIE_INT_VALUE] : 'Cookies',
  [INDEXED_DB_INT_VALUE]: 'Indexed DB',
  [TLD_COOKIE_INT_VALUE]: 'TLD Cookies',
};

export const BUILDER_SERVER_STORAGE_TYPE = {
  [STORAGE_TYPE.COOKIES]: COOKIE_INT_VALUE,
  [STORAGE_TYPE.INDEXED_DB]: INDEXED_DB_INT_VALUE,
  [STORAGE_TYPE.TLD_COOKIES]: TLD_COOKIE_INT_VALUE,
};

export const DEFAULT_WINDOW_VARIABLE_NAME = 'analytics';
const VALIDATION_STRING = /^[a-zA-Z_]+(?:[._][a-zA-Z_]+)*$/;
const VALIDATION_ERROR = 'Can only contain letters and underscores';
const PLACEHOLDER_TEXT = 'Enter a custom namespace, such as mr_analytics';
const FIELD_NAME = 'windowVariableName';
const PLACEHOLDER = DEFAULT_WINDOW_VARIABLE_NAME;
const LABEL = 'Namespace';

const STORAGE_PREFIX_LABEL = 'Storage Prefix';
export const STORAGE_PREFIX_DEFAULT_VALUE = '_meta';
const STORAGE_PREFIX_PLACEHOLDER = STORAGE_PREFIX_DEFAULT_VALUE;
const STORAGE_PREFIX_PLACEHOLDER_TEXT =
  'Set a unique prefix to differentiate MetaRouter identifiers from other data. This is useful if you have multiple applications or services that might conflict.';
const STORAGE_PREFIX_FIELD_NAME = 'storagePrefix';
const STORAGE_PREFIX_VALIDATION_STRING = /^[a-zA-Z_]+$/;
const STORAGE_PREFIX_VALIDATION_ERROR = 'Can only contain letters and underscores';
const DEFAULT_STORAGE_DATABASE_NAME = 'metarouter';
const STORAGE_DATABASE_NAME_VALIDATION_STRING = /^[A-Za-z]+$/;
const STORAGE_DATABASE_NAME_VALIDATION_ERROR = 'Can only contain letters';

const AnalyticsBuilderAdvancedSettings = ({ storageTypeValue, formState, setField, validateField, resetError }) => {
  return (
    <FeatureGate flagName={FEATURE_FLAGS.builderServerAdvancedSettings}>
      <MrInputText
        label={LABEL}
        onChange={setField}
        value={formState.windowVariableName?.value ?? DEFAULT_WINDOW_VARIABLE_NAME}
        placeholder={PLACEHOLDER}
        onBlur={validateField}
        onInput={resetError}
        helperText={PLACEHOLDER_TEXT}
        name={FIELD_NAME}
        errorMsg={formState.windowVariableName?.errorMsg}
        validators={[validatePattern(VALIDATION_STRING, VALIDATION_ERROR)]}
        fullWidth
        required
      />
      <MrInputText
        label={STORAGE_PREFIX_LABEL}
        onChange={setField}
        value={formState.storagePrefix?.value ?? STORAGE_PREFIX_DEFAULT_VALUE}
        placeholder={STORAGE_PREFIX_PLACEHOLDER}
        onBlur={validateField}
        onInput={resetError}
        helperText={STORAGE_PREFIX_PLACEHOLDER_TEXT}
        name={STORAGE_PREFIX_FIELD_NAME}
        errorMsg={formState.storagePrefix?.errorMsg}
        validators={[validatePattern(STORAGE_PREFIX_VALIDATION_STRING, STORAGE_PREFIX_VALIDATION_ERROR)]}
        fullWidth
        required
      />
      <MrInputRadioGroup
        overrideValue={storageTypeValue}
        name="storageType"
        label="Browser Storage Options"
        helperText="MetaRouter supports multiple browser storage mechanisms, each suited to different needs based on data scope, security, and functionality."
        onChange={setField}
        resetError={resetError}
        required
      >
        <MrInputRadio
          value={STORAGE_TYPE.COOKIES.toString()}
          label="Browser Cookies (scoped to subdomain)"
          tooltip="Stores identifiers in browser cookies scoped to a specific subdomain (e.g., sub.example.com). This can cause session IDs to change between subdomains, making it appear as if the user initiated multiple sessions. Not recommended for session tracking with MetaRouter."
        />
        <MrInputRadio
          value={STORAGE_TYPE.INDEXED_DB.toString()}
          label="IndexedDB"
          tooltip="Stores identifiers in the browser’s IndexedDB database. While useful for organizations with strict cookie policies, this storage method can also cause session IDs to change between subdomains, leading to fragmented session tracking. Not recommended for session tracking with MetaRouter."
        />
        <MrInputRadio
          value={STORAGE_TYPE.TLD_COOKIES.toString()}
          label="Browser Cookies (scoped to top-level domain)"
          tooltip="Stores identifiers in browser cookies scoped to the top-level domain (e.g., example.com). This ensures session IDs remain consistent across subdomains. Recommended setting for maintaining accurate session tracking."
        />
      </MrInputRadioGroup>
      {formState.storageType?.value === STORAGE_TYPE.INDEXED_DB.toString() && (
        <MrInputText
          label={'IndexedDB Database Name'}
          onChange={setField}
          value={formState.storageDatabase?.value ?? DEFAULT_STORAGE_DATABASE_NAME}
          placeholder={DEFAULT_STORAGE_DATABASE_NAME}
          onBlur={validateField}
          onInput={resetError}
          helperText="Choose a unique name for the IndexedDB database where MetaRouter will store identifiers."
          name="storageDatabase"
          errorMsg={formState.storageDatabase?.errorMsg}
          validators={
            formState.storageType?.value === STORAGE_TYPE.INDEXED_DB.toString()
              ? [validatePattern(STORAGE_DATABASE_NAME_VALIDATION_STRING, STORAGE_DATABASE_NAME_VALIDATION_ERROR)]
              : []
          }
          fullWidth
          visible={formState.storageType?.value === STORAGE_TYPE.INDEXED_DB.toString()}
          required
        />
      )}
    </FeatureGate>
  );
};

AnalyticsBuilderAdvancedSettings.propTypes = {
  formState: pt.object,
  setField: pt.func,
  validateField: pt.func,
  resetError: pt.func,
  storageTypeValue: pt.string,
};

export default AnalyticsBuilderAdvancedSettings;
