import { useEffect, useState } from 'react';
import { useGetPipelineSchemaViolations } from '@ion/api';
import { usePipelineCardContext } from 'app/pipelines/card/context/pipeline-card-context';

const NO_VIOLATIONS_CHIP_COLOR = 'success';
const HAS_VIOLATIONS_CHIP_COLOR = 'warning';

export default function useSchemaViolationsCount() {
  const { pipeline } = usePipelineCardContext();
  const [violations, setViolations] = useState([]);
  const [getPipelineSchemaViolations] = useGetPipelineSchemaViolations();

  useEffect(() => {
    const earliestSeen = new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString();
    getPipelineSchemaViolations({
      earliestSeen,
      clusterId: pipeline.clusterId,
      writekey: pipeline.writekey,
    }).then(data => {
      setViolations(data.violations);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline]);

  const violationsCount = Number(violations.reduce((acc, { count }) => acc + count, 0));
  const chipColor = violationsCount === 0 ? NO_VIOLATIONS_CHIP_COLOR : HAS_VIOLATIONS_CHIP_COLOR;

  return { violationsCount, chipColor };
}
