import React from 'react';
import pt from 'prop-types';

import AuditLog from './audit-log';
import Clusters from './clusters';
import Dashboard from './dashboard';
import EventMonitoring from './eventMonitoring';
import Help from './help';
import Integrations from './integrations';
import LogOut from './log-out';
import Pipelines from './pipelines';
import Settings from './settings';
import Schemas from './schemas';

const icons = {
  auditLog: { src: <AuditLog /> },
  clusters: { src: <Clusters /> },
  dashboard: { src: <Dashboard /> },
  eventMonitoring: { src: <EventMonitoring /> },
  eventDebugger: { src: <AuditLog /> },
  help: { src: <Help /> },
  integrations: { src: <Integrations /> },
  logOut: { src: <LogOut /> },
  pipelines: { src: <Pipelines /> },
  schemaReport: { src: <Schemas /> },
  schemas: { src: <Schemas /> },
  settings: { src: <Settings /> },
};

const Index = ({ icon }) => {
  const newProps = icons[icon];
  const src = newProps?.src;
  if (!newProps) {
    return null;
  }

  return src;
};

Index.propTypes = {
  icon: pt.string,
};

export default Index;
