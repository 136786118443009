import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Trash02Icon } from '@metarouter-private/mr-mui';
import isAllowedToWritePipelineSchemaLibrary from '@ion/api/pipeline-schema-libraries/isAllowedToWritePipelineSchemaLibrary';

export default function DeletePipelineSchemaLibraryButton({ onClick }) {
  const hasPermission = isAllowedToWritePipelineSchemaLibrary();

  return (
    <IconButton disabled={!hasPermission} data-test-id="deletePipelineSchemaLibraryButton" onClick={onClick}>
      <Trash02Icon />
    </IconButton>
  );
}

DeletePipelineSchemaLibraryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
