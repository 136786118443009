import React from 'react';

import { ConfirmDelete } from '@metarouter-private/mr-mui';

import ConfirmDeleteMessage from './ConfirmDeleteMessage';
import useConfirmDeleteModal from './useConfirmDeleteModal';

export default function ConfirmDeleteModal() {
  const { showDeleteModal, onClose, onConfirm, name } = useConfirmDeleteModal();

  return (
    <ConfirmDelete
      open={showDeleteModal}
      title={`Delete ${name}`}
      message={<ConfirmDeleteMessage name={name} />}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
}
