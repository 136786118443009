import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuerySchemaLibraryWithVersions } from '@ion/api';

import { SCHEMA_LIBRARY_VERSION_STATUS } from './status';

export default function useSchemaLibraryData() {
  const { state } = useLocation();
  const schemaLibraryId = state.schemaLibrary.id;
  const libraryName = state.schemaLibrary.name;
  const { data, loading } = useQuerySchemaLibraryWithVersions({ schemaLibraryId });
  const [activeVersion, setActiveVersion] = useState();
  const containsDraftVersion = data?.schemaLibrary.versions.some(
    ({ status }) => status === SCHEMA_LIBRARY_VERSION_STATUS.DRAFT
  );

  useEffect(() => {
    if (data) {
      setActiveVersion(data.schemaLibrary.versions[0]);
    }
  }, [data]);

  const updateActiveVersion = versionId => {
    const updatedActiveVersion = data.schemaLibrary.versions.find(({ id }) => id === versionId);
    setActiveVersion(updatedActiveVersion);
  };

  return {
    loading,
    schemaLibraryId,
    libraryName,
    activeVersion,
    updateActiveVersion,
    versions: data?.schemaLibrary.versions,
    containsDraftVersion,
  };
}
