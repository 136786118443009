import React from 'react';
import pt from 'prop-types';

import BaseTable from '../../base-table/BaseTable';
import { MrText, MrWithTooltip } from '@ion/components';
import { SelectInputFieldType } from './SelectInputFieldType';
import { shouldDisplayField } from './helpers';

const IdentitySyncFieldsTable = ({
  fields,
  syncName,
  setField,
  formState,
  validateField,
  resetError,
  validateNumber,
  validateRequired,
}) => {
  return (
    <BaseTable styleNames="noBorders">
      <tbody>
        {fields.map(field => {
          const {
            name: fieldName,
            required,
            type,
            defaultValue,
            description,
            friendlyName,
            displayIf,
            options,
            optionName,
          } = field;
          const isRequired = required ?? false;
          // Check if the field should be displayed
          const shouldDisplay = shouldDisplayField(displayIf, formState, syncName);

          if (!shouldDisplay) {
            return null; // Skip rendering this field
          }

          const title = (
            <MrText styleNames="bold" component="span" required={isRequired}>
              {friendlyName}
            </MrText>
          );
          return (
            <tr key={fieldName}>
              <td>{description.length ? <MrWithTooltip message={description}>{title} </MrWithTooltip> : title}</td>
              <td>
                <SelectInputFieldType
                  fieldName={fieldName}
                  required={isRequired}
                  type={type}
                  defaultFieldValue={defaultValue}
                  syncName={syncName}
                  validateField={validateField}
                  validateRequired={validateRequired}
                  formState={formState}
                  resetError={resetError}
                  validateNumber={validateNumber}
                  setField={setField}
                  options={options}
                  optionName={optionName}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </BaseTable>
  );
};
IdentitySyncFieldsTable.propTypes = {
  fields: pt.array.isRequired,
  syncName: pt.string.isRequired,
  setField: pt.func.isRequired,
  formState: pt.object.isRequired,
  validateField: pt.func.isRequired,
  resetError: pt.func.isRequired,
  validateNumber: pt.func.isRequired,
  validateRequired: pt.func.isRequired,
};
export default IdentitySyncFieldsTable;
