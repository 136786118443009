import React from 'react';

import TextCell from 'app/components/data-table/TextCell';
import FormatNumberCell from 'app/components/data-table/FormatNumberCell';
import ActionsCell from './cells/ActionsCell';

export const schemaLibrariesTableColumns = [
  {
    name: 'Name',
    cell: row => <TextCell text={row.name} />,
  },
  {
    name: 'Version',
    cell: row => <TextCell text={`Version ${row.latestSchemaLibraryVersion}`} />,
  },
  {
    name: 'Description',
    cell: row => <TextCell text={row.description} />,
  },
  {
    name: 'Pipeline Assignments',
    cell: row => <FormatNumberCell number={row.assignmentsCount} />,
  },
  {
    button: true,
    cell: row => <ActionsCell row={row} />,
  },
];
