import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import { useOrganizationsTable } from '../hooks/use-organizations-table';
import { COLUMNS } from './columns';
import { OrganizationOverrideTableTitle } from './OrganizationOverrideTableTitle';

const PER_PAGE = 50;

export const OrganizationOverrideTable = () => {
  const organizationsTable = useOrganizationsTable();

  return (
    <PageReactDataTable
      title={<OrganizationOverrideTableTitle />}
      columns={COLUMNS}
      perPage={PER_PAGE}
      {...organizationsTable}
    />
  );
};
