import React from 'react';
import PropTypes from 'prop-types';

import { FormFieldContext, FormSelect } from '@metarouter-private/mr-mui';

import useEventSelectConfig from './useEventSelectConfig';

export default function EventSelect({ events, defaultValue }) {
  const { options, normalizedDefaultValue } = useEventSelectConfig({ events, defaultValue });

  return (
    <FormFieldContext name="event" label="Event" defaultValue={normalizedDefaultValue} required>
      <FormSelect options={options} data-test-id="eventSelect" />
    </FormFieldContext>
  );
}

EventSelect.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultValue: PropTypes.string,
};
