import React from 'react';
import PropTypes from 'prop-types';
import { ModalLauncher } from '@metarouter-private/mr-mui';
import DeletePipelineSchemaLibraryButton from './DeletePipelineSchemaLibraryButton';
import ConfirmRemovePipelineSchemaLibraryModal from './ConfirmRemovePipelineSchemaLibraryModal';

export default function DeleteAction({ row }) {
  return (
    <ModalLauncher
      Launcher={DeletePipelineSchemaLibraryButton}
      Modal={ConfirmRemovePipelineSchemaLibraryModal}
      modalProps={row}
    />
  );
}

DeleteAction.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
