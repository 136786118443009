import React from 'react';

import AppHeader from 'app/components/app-header';
import { routes } from 'app/constants';

import { useSchemaLibraryPageContext } from './SchemaLibraryPageContext';

const SCHEMA_ENFORCEMENT = 'Schema Enforcement';

export default function SchemaLibraryPageHeader() {
  const { libraryName } = useSchemaLibraryPageContext();

  return <AppHeader icon="schemaReport" title={SCHEMA_ENFORCEMENT} route={routes.schemas} subTitle={libraryName} />;
}

SchemaLibraryPageHeader.propTypes = {};
