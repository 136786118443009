import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const SchemaViolationsPageContext = createContext(null);

export default function SchemaViolationsPageContextProvider({ children }) {
  const {
    state: { pipeline },
  } = useLocation();

  return <SchemaViolationsPageContext.Provider value={{ pipeline }}>{children}</SchemaViolationsPageContext.Provider>;
}

export function useSchemaViolationsPageContext() {
  return useContext(SchemaViolationsPageContext);
}

SchemaViolationsPageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
