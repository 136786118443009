/* eslint-disable filename-export/match-default-export */
/* disabling rule until refactor of analytics builder */

import React from 'react';
import pt from 'prop-types';
import { MrH, MrInputText } from '@ion/components';
import s from './index.module.scss';
import AnalyticsBuilderFileHosting, { HOSTING_TYPES } from './analytics-builder-file-hosting';
import AnalyticsBuilderConsent from './analytics-builder-consent';
import AnalyticsBuilderAdvancedSettingsAccordion from './analytics-builder-advanced-settings-accordion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../../launch-darkly/is-feature-enabled';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';

const CDN_HOSTING_LABEL = 'CDN Hosting URL';
const ANALYTICS_JS_HOSTING_LABEL = 'Analytics.js Hosting URL';
const CDN_HOSTING_HELPER_TEXT =
  'The CDN URL where your Analytics.js file will be cached after fetching from the MetaRouter Cluster.';
const ANALYTICS_JS_HOSTING_HELPER_TEXT = 'Where the Analytics.js file will be located';

const AnalyticsBuilderSettings = ({
  formState,
  setField,
  validateField,
  validateRequired,
  validateUrl,
  validateNoPath,
  resetError,
}) => {
  const featureFlags = useFlags();
  const isUploadToClusterEnabled = isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.uploadAjsFileToCluster });
  const isMRHosted = formState.fileHostingType?.value === HOSTING_TYPES.metaRouter;

  const validURLMsg = `Value must be a URL and cannot end with "/"`;
  return (
    <div>
      <section className={s.section}>
        <MrH h="h5" sans styleNames="noMargin">
          <div className={s.circle}>1</div> File location and settings
        </MrH>
        <div className={s.subSection}>
          <AnalyticsBuilderFileHosting
            value={formState?.fileHostingType?.value}
            onChange={setField}
            errorMsg={formState.fileHostingType?.errorMsg}
            resetError={resetError}
            required
          />

          <MrInputText
            label={isUploadToClusterEnabled && isMRHosted ? CDN_HOSTING_LABEL : ANALYTICS_JS_HOSTING_LABEL}
            onChange={setField}
            value={formState.cdnURL?.value ?? ''}
            onBlur={validateField}
            onInput={resetError}
            name="cdnURL"
            errorMsg={formState.cdnURL?.errorMsg}
            helperText={
              isUploadToClusterEnabled && isMRHosted ? CDN_HOSTING_HELPER_TEXT : ANALYTICS_JS_HOSTING_HELPER_TEXT
            }
            validators={[
              validateRequired(),
              validateUrl(validURLMsg, false),
              ...(isUploadToClusterEnabled ? [validateNoPath(isMRHosted)] : []),
            ]}
            placeholder={isMRHosted ? 'cdn.metarouter.io' : 'yourdomain.com/scripts'}
            prefix="https://"
            fullWidth
            required
          />

          <MrInputText
            label="Ingestion Website URL"
            onChange={setField}
            value={formState.host?.value ?? ''}
            onBlur={validateField}
            onInput={resetError}
            name="host"
            errorMsg={formState.host?.errorMsg}
            validators={[validateRequired(), validateUrl(validURLMsg, false)]}
            helperText="The event ingestion endpoint for your MetaRouter Cluster."
            placeholder="yourdomain.com"
            prefix="https://"
            fullWidth
            required
          />
        </div>
      </section>
      <section className={s.section} style={{ paddingTop: 20, paddingBottom: 20 }}>
        <AnalyticsBuilderAdvancedSettingsAccordion
          formState={formState}
          setField={setField}
          validateField={validateField}
          resetError={resetError}
        />
      </section>
      <section className={s.section}>
        <MrH h="h5" sans styleNames="noMargin">
          <div className={s.circle}>2</div>
          Compliance Settings
        </MrH>
        <div className={s.subSection}>
          <AnalyticsBuilderConsent
            value={formState.impliedConsent?.value}
            onChange={setField}
            resetError={resetError}
            errorMsg={formState.impliedConsent?.errorMsg}
          />

          <MrInputText
            className={s.textField}
            label="Cookie Name"
            onChange={setField}
            value={formState.cookieName?.value ?? ''}
            onBlur={validateField}
            onInput={resetError}
            name="cookieName"
            errorMsg={formState.cookieName?.errorMsg}
            validators={[validateRequired()]}
            helperText="This needs to match with the cookie name in your Consent Manager."
            placeholder="OptOutOnRequest"
            fullWidth
            required
          />
        </div>
      </section>
    </div>
  );
};

AnalyticsBuilderSettings.propTypes = {
  writekey: pt.string,
  formState: pt.object,
  setField: pt.func,
  validateField: pt.func,
  validateRequired: pt.func,
  validateUrl: pt.func,
  validateNoPath: pt.func,
  resetError: pt.func,
};

export default AnalyticsBuilderSettings;
