import React from 'react';
import pt from 'prop-types';
import { MrText } from '@ion/components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import BaseTable from '../../base-table/BaseTable';
import isFeatureEnabled from '../../../../launch-darkly/is-feature-enabled';
import { GatedConsentCategoriesSelect } from '../../../consent-categories-select/GatedConsentCategoriesSelect';

const ConsentCategoryTable = ({ formState, setField, validateRequired, validateField, resetError, syncName }) => {
  const consentCategorySelectName = `sync-${syncName}-consentCategoryId`;
  const featureFlags = useFlags();
  const hasConsentModule = isFeatureEnabled({ featureFlags, flagName: 'ecmConfigurableConsent' });
  return (
    <BaseTable styleNames="noBorders">
      <tbody>
        <tr key={'consent-type'}>
          <td>
            <MrText styleNames="bold" component="span" required={hasConsentModule}>
              Consent Type
            </MrText>
          </td>
          <td>
            <GatedConsentCategoriesSelect
              errorMsg={formState[consentCategorySelectName]?.errorMsg}
              onChange={setField}
              value={formState[consentCategorySelectName]?.value}
              name={consentCategorySelectName}
              validators={[validateRequired()]}
              onBlur={validateField}
              onInput={resetError}
            />
          </td>
        </tr>
      </tbody>
    </BaseTable>
  );
};

ConsentCategoryTable.propTypes = {
  formState: pt.object.isRequired,
  setField: pt.func.isRequired,
  validateField: pt.func.isRequired,
  validateRequired: pt.func.isRequired,
  resetError: pt.func.isRequired,
  syncName: pt.string.isRequired,
};
export default ConsentCategoryTable;
