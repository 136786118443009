import { isValidYaml } from '@ion/mrdash';

export const ERROR_MESSAGES = {
  INVALID_YAML: 'Invalid Yaml',
};

export default function determineErrorMessage(value, customErrorFn) {
  if (!isValidYaml(value)) {
    return ERROR_MESSAGES.INVALID_YAML;
  }

  if (customErrorFn) {
    const error = customErrorFn(value);
    if (error) {
      return error; // Return custom error if present
    }
  }

  return null;
}
