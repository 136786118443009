import { gql } from '@apollo/client';
import schemaLibraryEventSchema from 'api/schema-library-event-schemas/schemaLibraryEventSchema';

export const mutation = gql`
  mutation InsertEventSchema($event_schemas_insert_input: schema_library_event_schemas_insert_input!) {
    eventSchema: insert_schema_library_event_schemas_one(
      object: $event_schemas_insert_input
      on_conflict: { constraint: schema_library_event_schemas_event_name_id_schema_version_i_key, update_columns: [] }
    ) {
      ...schemaLibraryEventSchema
    }
  }
  ${schemaLibraryEventSchema}
`;

export function buildVariables({ eventNameId, schemaVersionId, schemaLibraryVersionId }) {
  return {
    event_schemas_insert_input: {
      eventNameId,
      schemaVersionId,
      schemaLibraryVersionId,
    },
  };
}
