import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalContext from './ModalContext';

const DEFAULT_STATE = {
  activeModal: null,
  modalData: null,
};

export default function ModalContextProvider({ children }) {
  const [{ activeModal, modalData }, setModalState] = useState(DEFAULT_STATE);

  const openModal = (modal, modalData) => {
    setModalState({
      activeModal: modal,
      modalData,
    });
  };

  const closeModal = () => {
    setModalState(DEFAULT_STATE);
  };

  return (
    <ModalContext.Provider value={{ activeModal, modalData, openModal, closeModal }}>{children}</ModalContext.Provider>
  );
}

ModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
