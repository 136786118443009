/* eslint-disable filename-export/match-default-export */

import React from 'react';
import CodeEditorContainer, { editorPropTypes } from './code-editor-container';
import SimpleCodeEditor from './simple-code-editor';

export default function MrCodeEditor(props) {
  return <CodeEditorContainer {...props} CodeEditor={SimpleCodeEditor} />;
}

MrCodeEditor.propTypes = editorPropTypes;
