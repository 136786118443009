import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@metarouter-private/mr-mui';

import NameField from './NameField';
import DescriptionField from './DescriptionField';
import SchemaField from './SchemaField';

export default function SchemaForm({ versionId, name, nameDisabled, description, jsonSchema, loading = false }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <NameField defaultValue={name} disabled={nameDisabled} />
          </Grid>

          <Grid item xs={8}>
            <DescriptionField defaultValue={description} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SchemaField versionId={versionId} defaultValue={jsonSchema} loading={loading} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SchemaForm.propTypes = {
  versionId: PropTypes.string,
  name: PropTypes.string,
  nameDisabled: PropTypes.bool,
  description: PropTypes.string,
  jsonSchema: PropTypes.string,
  loading: PropTypes.bool,
};
