import { useInsertEventSchema } from '@ion/api';

export const useCreateEventSchema = (schemaLibraryVersionId, onClose) => {
  const [createEventSchema] = useInsertEventSchema({ onCompleted: onClose });
  const handleCreateEventSchema = ({ eventNameId, schemaVersionId }) => {
    createEventSchema({ schemaLibraryVersionId, eventNameId, schemaVersionId });
  };

  return handleCreateEventSchema;
};
