export const AJS_SETTINGS_FIELDS = {
  fileHostingType: 'File Hosting Type',
  cdnURL: 'CDN Hosting URL',
  host: 'Ingestion Website URL',
  impliedConsent: 'Consent Policy',
  cookieName: 'Cookie Name',
  storageType: 'Advanced Settings > Storage Type',
  windowVariableName: 'Advanced Settings > Namespace',
  storagePrefix: 'Advanced Settings > Storage Prefix',
  storageDatabase: 'Advanced Settings > Storage Database',
};

const AJS_INTEGRATION_OPTION = {
  sync: 'sync',
  integration: 'integration',
};
const CONSENT_CATEGORY_FIELD_NAME = 'consentCategoryId';
const CROSS_DOMAIN_OPTION = 'crossDomain';

const getFieldInformation = field => {
  const fieldArray = field.split('-');
  const category = fieldArray[0];
  const name = fieldArray.slice(1, fieldArray.length - 1).join('-');
  const fields = fieldArray[fieldArray.length - 1];
  return [category, name, fields];
};

export const friendlyFieldName = (field, integrations, identitySyncs) => {
  const settingsField = AJS_SETTINGS_FIELDS[field];
  if (settingsField) {
    return settingsField;
  }

  const [category, name, fields] = getFieldInformation(field);

  if (name === CROSS_DOMAIN_OPTION) {
    if (fields === CONSENT_CATEGORY_FIELD_NAME) {
      return `Cross Domain Consent Type`;
    }
  }
  if (category === AJS_INTEGRATION_OPTION.sync) {
    const identitySync = identitySyncs.find(sync => sync.name === name);

    if (fields === CONSENT_CATEGORY_FIELD_NAME) {
      return `${identitySync.friendlyName} Consent Type`;
    }
    if (identitySync !== undefined) {
      const syncField = identitySync.fields.find(syncField => syncField.name === fields);
      return `${identitySync.friendlyName} ${syncField.friendlyName}`;
    }
    // TODO osvy: map byo sync fields
    return field;
  }

  if (category === AJS_INTEGRATION_OPTION.integration) {
    const integration = integrations.find(eachIntegration => eachIntegration.name === name);
    const integrationField = integration.fields.find(integrationField => integrationField.name === fields);
    return `${integration.friendlyName} ${integrationField.friendlyName}`;
  }
  return field;
};
