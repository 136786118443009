import React from 'react';

import { Button } from '@metarouter-private/mr-mui';

import useSchemaViolationsButtonProps from './useSchemaViolationsButtonProps';

const SCHEMA_VIOLATIONS_BUTTON_LABEL = 'Schema Violations';

export default function SchemaViolationsButton() {
  const buttonProps = useSchemaViolationsButtonProps();

  return <Button {...buttonProps}>{SCHEMA_VIOLATIONS_BUTTON_LABEL}</Button>;
}
