import { validate } from 'parameter-validator';

import mergeQueries from 'api/Apollo/merge-queries';
import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import useTrackWithUser from 'api/hooks/useTrackWithUser';

import { upsertPipelineSchemaLibraryMutation, buildVariables } from './upsert-pipeline-schema-library-mutation-builder';
import {
  buildVariables as buildAuditLogVariables,
  schemaLibraryAddedMutation,
} from 'api/audit-logs/schema-library-added-to-pipeline/audit-log-mutation-builder';

import { GET_PIPELINE_SCHEMA_LIBRARY } from 'api/pipelines/schema-library/pipeline-schema-library-query-builder';

export const combinedMutation = mergeQueries('UpsertPipelineSchemaLibraryAndAudit', [
  upsertPipelineSchemaLibraryMutation,
  schemaLibraryAddedMutation,
]);

export default function useUpsertPipelineSchemaLibrary(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_enforcement_added_to_pipeline');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    refetchQueries: [GET_PIPELINE_SCHEMA_LIBRARY],
    context: { permission: permissions.createSchemas },
  });

  const upsertPipelineSchemaLibraryMutation = args => {
    const { id, pipelineId, schemaLibraryVersionId, pipelineName, schemaLibraryName } = validate(args, [
      'pipelineId',
      'schemaLibraryVersionId',
      'pipelineName',
      'schemaLibraryName',
    ]);
    return executeMutation({
      variables: {
        ...buildVariables({
          id,
          pipelineId,
          schemaLibraryVersionId,
        }),
        ...buildAuditLogVariables({
          pipelineId,
          pipelineName,
          schemaLibraryVersionId,
          schemaLibraryName,
        }),
      },
    }).then(response => {
      if (!response.errors) {
        trackWithUser({ pipelineId, schemaLibraryVersionId, pipelineName, schemaLibraryName });
      }
      return response;
    });
  };

  return [upsertPipelineSchemaLibraryMutation, response];
}
