import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { FormModal, PlusCircleIcon } from '@metarouter-private/mr-mui';
import { useInsertSchemaLibrary } from '@ion/api';

import { routes } from 'app/constants';
import SchemaLibraryForm from './shared/SchemaLibraryForm';

const MODAL_CONFIG = {
  title: 'New Schema Library',
  TitleIcon: PlusCircleIcon,
  submitLabel: 'Create Schema Library',
};

export default function CreateSchemaLibraryModal({ open, onClose }) {
  const history = useHistory();
  const [createSchemaLibrary] = useInsertSchemaLibrary({
    onCompleted: ({ schemaLibrary }) => {
      history.push({
        pathname: routes.schemaLibrary,
        state: { schemaLibrary },
      });
    },
  });

  return (
    <FormModal open={open} onClose={onClose} onSubmit={createSchemaLibrary} {...MODAL_CONFIG}>
      <SchemaLibraryForm />
    </FormModal>
  );
}

CreateSchemaLibraryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
