import { useLazyQuerySchemaLibraryVersion } from 'api';
import useDeployPipelineSchemas from './useDeployPipelineSchemas';

export default function useDeploySchemasToPipeline({ pipelineSchemaLibraryId, pipeline, ...options }) {
  const [deployPipelineSchemas, response] = useDeployPipelineSchemas({ pipelineSchemaLibraryId, pipeline, ...options });
  const [getSchemaLibraryVersion] = useLazyQuerySchemaLibraryVersion({ onCompleted: deployPipelineSchemas });

  const upsertPipelineSchemas = ({ schemaLibraryVersionId }) => {
    getSchemaLibraryVersion({ pipelineId: pipeline.id, schemaLibraryVersionId });
  };

  return [upsertPipelineSchemas, response];
}
