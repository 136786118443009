import React from 'react';

import { Edit03Icon } from '@metarouter-private/mr-mui';

import useEditEventSchemaModal from './useEditEventSchemaModal';
import EventSchemaFormModal from 'app/schema-enforcement/SchemaLibraryPage/modals/shared/EventSchemaFormModal';

const MODAL_CONFIG = {
  title: 'Edit Event Schema',
  TitleIcon: Edit03Icon,
  submitLabel: 'Edit Event Schema',
};

export default function EditEventSchemaModal() {
  const { open, eventId, schemaId, schemaVersionId, onSubmit, onClose } = useEditEventSchemaModal();

  return (
    <EventSchemaFormModal
      open={open}
      onSubmit={onSubmit}
      eventId={eventId}
      schemaId={schemaId}
      schemaVersionId={schemaVersionId}
      onClose={onClose}
      {...MODAL_CONFIG}
    />
  );
}
