import useQuery from 'api/hooks/useQuery';
import permissions from 'api/Apollo/permissions';
import { GET_PIPELINE_SCHEMA_LIBRARY, buildVariables } from './pipeline-schema-library-query-builder';

export default function useQueryPipelineSchemaLibrary({ pipelineId, ...options } = {}) {
  const { data, loading, isAllowed, error } = useQuery(GET_PIPELINE_SCHEMA_LIBRARY, {
    context: { permission: permissions.readSchemas },
    variables: buildVariables({ pipelineId }),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  return { data: data?.pipeline.schemaLibrary, loading, isAllowed, error };
}
