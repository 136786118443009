import { ALL_EVENTS, ALL_EVENTS_VALUE } from 'app/schema-enforcement/SchemaLibraryPage/shared/constants';
import { buildSelectOptions } from '@ion/mrdash';
import { useMemo } from 'react';

const ALL_EVENTS_OPTION = {
  label: ALL_EVENTS,
  value: ALL_EVENTS_VALUE,
};

export default function useEventSelectConfig({ events, defaultValue }) {
  const options = useMemo(() => {
    const eventOptions = buildSelectOptions({ items: events, labelKey: 'name', valueKey: 'id' });
    return [ALL_EVENTS_OPTION, ...eventOptions];
  }, [events]);

  const normalizedDefaultValue = defaultValue || ALL_EVENTS_OPTION.value;

  return {
    options,
    normalizedDefaultValue,
  };
}
