import React, { useMemo } from 'react';
import pt from 'prop-types';

import { isNil as _isNil } from 'lodash';

import { MrButtonGroup, MrH, MrInputMultiSelect } from '@ion/components';

import s from './index.module.scss';

import getPipelineAndClusterOptions from '../get-pipeline-and-cluster-options';
import getDefaultOptions from './get-default-options';
import IntegrationSelect from './IntegrationSelect';
import GROUP_BY_OPTIONS from '../group-by-options';
import DateTimeRangePicker from './DateTimeRangePicker';

const groupByButtons = [
  { value: 'integration', label: 'Integration', icon: 'icon-integrations' },
  { value: 'pipeline', label: 'Pipeline', icon: 'icon-pipelines' },
];

const DashboardHeader = ({
  setQueryParameter,
  clusterOptions,
  since,
  until,
  groupBy,
  setGroupBy,
  configuredClusters,
  configuredPipelines,
  integrations,
  isLoadingMetrics,
  noDataComponentMessage,
}) => {
  const isDisabled = isLoadingMetrics && _isNil(noDataComponentMessage);

  const defaultOptions = useMemo(() => getDefaultOptions(clusterOptions), [clusterOptions]);
  const handleMultiSelectChange = e => {
    const selected = e.target.value.map(v => v.value);
    const { clusters, pipelines } = getPipelineAndClusterOptions(selected);

    setQueryParameter({
      clusters,
      pipelines,
      filterCount: selected.length,
      defaultFilter: false,
    });
  };

  return (
    <div className={s.header}>
      <div className={s.headerSection1}>
        <MrH h="h2" className={s.groupBy}>
          Group by
        </MrH>
        <MrButtonGroup
          disabled={isDisabled}
          className={s.groupByButtonGroup}
          items={groupByButtons}
          overrideValue={groupBy}
          defaultValue={groupBy}
          onClick={e => {
            setGroupBy(e);
          }}
        />

        <MrH h="h2" className={s.filter}>
          Filter
        </MrH>

        {groupBy === GROUP_BY_OPTIONS.INTEGRATION && (
          <MrInputMultiSelect
            disabled={isDisabled}
            className={s.multiSelectDropdown}
            name="multiSelectClusterOptionDropdown"
            options={clusterOptions}
            onChange={handleMultiSelectChange}
            optionName="Pipeline"
            defaultValue={defaultOptions}
          />
        )}

        {groupBy === GROUP_BY_OPTIONS.PIPELINE && (
          <IntegrationSelect
            isDisabled={isDisabled}
            since={since}
            until={until}
            configuredClusters={configuredClusters}
            configuredPipelines={configuredPipelines}
            integrations={integrations}
            setQueryParameter={setQueryParameter}
          />
        )}
      </div>
      <div className={s.headerSection2}>
        <DateTimeRangePicker
          isDisabled={isDisabled}
          since={since}
          until={until}
          setQueryParameter={setQueryParameter}
          groupBy={groupBy}
        />
      </div>
    </div>
  );
};

DashboardHeader.propTypes = {
  setQueryParameter: pt.func.isRequired,
  clusterOptions: pt.array,
  since: pt.instanceOf(Date).isRequired,
  until: pt.instanceOf(Date).isRequired,
  groupBy: pt.string.isRequired,
  setGroupBy: pt.func.isRequired,
  configuredClusters: pt.array.isRequired,
  configuredPipelines: pt.array.isRequired,
  integrations: pt.array,
  isLoadingMetrics: pt.bool.isRequired,
  noDataComponentMessage: pt.string,
};

export default DashboardHeader;
