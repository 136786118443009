import { v4 as uuid } from 'uuid';
import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permission from 'api/Apollo/permissions';
import { GET_EVENT_NAMES } from 'api/event-names/event-names-query-builder';
import mergeQueries from 'api/Apollo/merge-queries';

import {
  mutation as insertEventNameMutation,
  buildVariables as buildInsertEventNameVariables,
} from './insert-event-name-mutation-builder';

import {
  mutation as eventNameCreatedMutation,
  buildVariables as buildEventNameCreatedVariables,
} from 'api/audit-logs/event-name-created/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('InsertEventNameAndAudit', [
  insertEventNameMutation,
  eventNameCreatedMutation,
]);

export default function useInsertEventName(options = {}) {
  const [trackWithUser] = useTrackWithUser('event_name_created');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    refetchQueries: [GET_EVENT_NAMES],
    context: { permission: permission.createEventNames },
  });

  const insertEventNameMutation = async ({ name, description }) => {
    const id = uuid();
    const response = executeMutation({
      variables: {
        ...buildInsertEventNameVariables({
          id,
          name,
          description,
        }),
        ...buildEventNameCreatedVariables({
          id,
          name,
          description,
        }),
      },
    });

    if (!response.errors) {
      trackWithUser();
    }

    return response;
  };

  return [insertEventNameMutation, response];
}
