import React, { useEffect, useState } from 'react';
import pt from 'prop-types';

import FeatureGate from '../../../../launch-darkly/gates/feature-gate';
import { CONSENT_CATEGORY_FLAGS } from '../../../../context/gated-consent-categories-context';

import { IdentitySyncFieldsTable, ConsentCategoryTable } from './index';
import { Typography } from '@metarouter-private/mr-mui';
import s from '../../index.module.scss';

const GoogleTagParentBody = ({
  stagedSyncs,
  resetError,
  formState,
  setField,
  validateField,
  validateRequired,
  validateNumber,
}) => {
  const [parentFields, setParentFields] = useState([]);

  useEffect(() => {
    const uniqueFields = new Map();

    stagedSyncs.forEach(sync => {
      if (sync.parentFields) {
        sync.parentFields.forEach(field => {
          if (!uniqueFields.has(field.name)) {
            uniqueFields.set(field.name, field);
          }
        });
      }
    });

    setParentFields(Array.from(uniqueFields.values()));
  }, [stagedSyncs]);

  return (
    <div>
      <Typography className={s.borderBottom} variant="h4">
        Parent Fields
      </Typography>
      <IdentitySyncFieldsTable
        fields={parentFields}
        syncName="parentGTag"
        setField={setField}
        formState={formState}
        validateField={validateField}
        resetError={resetError}
        validateNumber={validateNumber}
        validateRequired={validateRequired}
      />
      <Typography className={s.borderBottom} variant="h4">
        Syncs
      </Typography>
      {stagedSyncs.map(sync => {
        return (
          <React.Fragment key={sync.name}>
            <Typography sx={{ mt: '26px', mb: 2, pl: '10px' }} variant="h5">
              {sync.friendlyName}
            </Typography>
            <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.MVP}>
              <ConsentCategoryTable
                resetError={resetError}
                formState={formState}
                setField={setField}
                syncName={sync.name}
                validateField={validateField}
                validateRequired={validateRequired}
                validateNumber={validateNumber}
              />
            </FeatureGate>
            <IdentitySyncFieldsTable
              fields={sync.fields}
              syncName={sync.name}
              setField={setField}
              formState={formState}
              validateField={validateField}
              resetError={resetError}
              validateNumber={validateNumber}
              validateRequired={validateRequired}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

GoogleTagParentBody.propTypes = {
  stagedSyncs: pt.array,
  resetError: pt.func,
  formState: pt.object,
  setField: pt.func,
  syncName: pt.string,
  validateField: pt.func,
  validateRequired: pt.func,
  validateNumber: pt.func,
};

export default GoogleTagParentBody;
