import { gql } from '@apollo/client';
import eventName from 'api/event-names/eventName';

export const mutation = gql`
  mutation InsertEventName($event_names_insert_input: event_names_insert_input!) {
    eventName: insert_event_names_one(
      object: $event_names_insert_input
      on_conflict: { constraint: event_names_name_org_id_key, update_columns: [] }
    ) {
      ...eventName
    }
  }
  ${eventName}
`;

export function buildVariables({ id, name, description }) {
  return {
    event_names_insert_input: {
      id,
      name,
      description,
    },
  };
}
