/* eslint-disable filename-export/match-default-export */

import { isNil, pick } from 'lodash';

const DEFAULT_PROPS = {
  className: 'aceTheme',
  fontSize: 14, // TODO: this should pull from the MUI theme
  lineHeight: 19, // TODO: this should pull from the MUI theme
  showGutter: true,
  showPrintMargin: false,
  wrapEnabled: true,
  highlightActiveLine: false,
  width: '100%',
};

export default function buildAceEditorProps(props) {
  return {
    ...DEFAULT_PROPS,
    ...buildMode(props),
    ...buildPassThroughProps(props),
    ...buildLines(props),
    ...buildOptions(props),
    ...buildOnChange(props),
  };
}

function buildMode({ lang }) {
  return {
    mode: lang,
  };
}

const PASS_THROUGH_PROPS = ['onBlur', 'onFocus', 'defaultValue', 'value'];

function buildPassThroughProps(props) {
  return pick(props, PASS_THROUGH_PROPS);
}

function buildLines({ rows }) {
  if (isNil(rows)) {
    return;
  }
  return {
    maxLines: rows,
  };
}

const DEFAULT_OPTIONS = {
  enableBasicAutocompletion: false,
  enableLiveAutocompletion: false,
  enableSnippets: false,
  showLineNumbers: true,
  useWorker: false,
  tabSize: 2,
  foldStyle: 'manual',
  showFoldWidgets: false,
  displayIndentGuides: false,
};

function buildOptions({ options }) {
  return {
    setOptions: {
      ...DEFAULT_OPTIONS,
      ...options,
    },
  };
}

// ND: The AceEditor passes the value back
// but for backwards compatability I'm going to pass the event
// instead. In the future if we're just using Ace we can refactor this.
function buildOnChange({ onChange }) {
  if (isNil(onChange)) {
    return;
  }
  return {
    onChange: value => {
      onChange({ target: { value } });
    },
  };
}
