import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import { eventsTableColumns } from './eventsTableColumns';
import EventsTableActions from './EventsTableActions';
import useEventsTable from './useEventsTable';

const EVENTS_PER_PAGE = 10;

export default function EventsTable() {
  const eventsTable = useEventsTable();

  return (
    <PageReactDataTable
      columns={eventsTableColumns}
      perPage={EVENTS_PER_PAGE}
      {...eventsTable}
      actions={<EventsTableActions />}
    />
  );
}
