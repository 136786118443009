import React from 'react';
import EventNameCell from './EventNameCell';
import TextCell from 'app/components/data-table/TextCell';
import TimestampCell from 'app/components/data-table/TimestampCell';
import FormatNumberCell from 'app/components/data-table/FormatNumberCell';

const schemaViolationsTableColumns = [
  {
    name: 'Event Name',
    cell: row => <EventNameCell row={row} />,
  },
  {
    name: 'Schema Name',
    cell: row => <TextCell text={row.schemaName} />,
  },
  {
    name: 'Violations',
    cell: row => <FormatNumberCell number={row.count} />,
  },
  {
    name: 'Last Seen',
    cell: row => <TimestampCell timestamp={row.lastSeen} />,
  },
];

export default schemaViolationsTableColumns;
