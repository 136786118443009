import React from 'react';
import PropTypes from 'prop-types';

import { ModalLauncher, Stack } from '@metarouter-private/mr-mui';
import EditEventModal from 'app/schema-enforcement/EventsTab/EventsTable/modals/EditEventModal';

import DeleteEventButton from './DeleteEventButton';
import EditEventButton from './EditEventButton';

const STACK_PROPS = {
  alignItems: 'center',
  direction: 'row',
};

export default function ActionsCell({ row }) {
  return (
    <Stack {...STACK_PROPS}>
      <ModalLauncher Launcher={EditEventButton} launcherProps={{ row }} Modal={EditEventModal} modalProps={row} />
      <DeleteEventButton row={row} />
    </Stack>
  );
}

ActionsCell.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};
