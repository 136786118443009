import React from 'react';
import PropTypes from 'prop-types';

import { Edit03Icon, IconButton } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';

export default function EditButton({ onClick, disabled, disabledMessage, dataTestId }) {
  if (!onClick) {
    return null;
  }

  return (
    <WithTooltip hasPermission={!disabled} title={disabledMessage}>
      <IconButton data-test-id={dataTestId} onClick={onClick} disabled={disabled}>
        <Edit03Icon />
      </IconButton>
    </WithTooltip>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  dataTestId: PropTypes.string,
};
