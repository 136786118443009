import React from 'react';

import { Button } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';

import useActionButtonProps from './useActionButtonProps';

export default function ActionButtons() {
  const { label, onClick, disabled, disabledMessage } = useActionButtonProps();

  return (
    <WithTooltip hasPermission={!disabled} title={disabledMessage}>
      <Button size="large" onClick={onClick} disabled={disabled}>
        {label}
      </Button>
    </WithTooltip>
  );
}
