import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { useGetPipelineSchemaViolations, useQueryAllSchemas } from '@ion/api';

import { useSchemaViolationsPageContext } from 'app/schema-violations/SchemaViolationsPage/SchemaViolationsPageContextProvider';

import schemaViolationsTableColumns from './schemaViolationsTableColumns';

const STATIC_PROPS = {
  columns: schemaViolationsTableColumns,
  pagination: true,
};

// TODO: Replace when we create time select.
const earliestSeen = new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString();

export default function useSchemaViolationsTableProps() {
  const { pipeline } = useSchemaViolationsPageContext();
  const [getPipelineSchemaViolations, { loading: violationsLoading, data: violations }] =
    useGetPipelineSchemaViolations();
  const { loading: schemaLoading, data: schemas } = useQueryAllSchemas();

  useEffect(() => {
    getPipelineSchemaViolations({
      earliestSeen,
      clusterId: pipeline.clusterId,
      writekey: pipeline.writekey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline.clusterId, pipeline.writekey]);

  return {
    ...STATIC_PROPS,
    loading: violationsLoading || schemaLoading,
    data: mapViolationsWithSchemaName(violations, schemas),
  };
}

function mapViolationsWithSchemaName(violations, schemas) {
  if (isEmpty(violations) || isEmpty(schemas)) {
    return [];
  }

  return violations.map(violation => ({
    ...violation,
    schemaName: schemas.find(schema => schema.id === violation.schemaId)?.name,
  }));
}
