import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Trash02Icon } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';
import tooltipTitle from 'app/schema-enforcement/SchemaLibraryPage/EventSchemasTable/tooltipTitle';
import { useEventSchemaButton } from './useEventSchemaButton';

export default function DeleteEventSchemaButton({ row }) {
  const { hasPermission, onClick } = useEventSchemaButton({ type: 'delete', row });

  return (
    <WithTooltip hasPermission={hasPermission} title={tooltipTitle}>
      <IconButton data-test-id="deleteEventSchemaButton" disabled={!hasPermission} onClick={onClick}>
        <Trash02Icon />
      </IconButton>
    </WithTooltip>
  );
}

DeleteEventSchemaButton.propTypes = {
  row: PropTypes.object.isRequired,
};
