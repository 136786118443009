import React from 'react';

import { Stack } from '@metarouter-private/mr-mui';

import SchemaViolationsButton from './SchemaViolationsButton';
import SchemaViolationsCount from './SchemaViolationsCount';

export default function SchemaViolationsLink() {
  return (
    <Stack direction="row" spacing={0}>
      <SchemaViolationsCount />
      <SchemaViolationsButton />
    </Stack>
  );
}
