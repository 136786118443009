import React from 'react';
import PropTypes from 'prop-types';

import { FormModal, PlusCircleIcon } from '@metarouter-private/mr-mui';

import SchemaForm from 'app/schema-enforcement/SchemasTab/modals/shared/SchemaForm';
import SCHEMA_FORM_MODAL_CONFIG from 'app/schema-enforcement/SchemasTab/modals/shared/SCHEMA_FORM_MODAL_CONFIG';
import useCreateSchema from './useCreateSchema';

const STATIC_MODAL_PROPS = {
  ...SCHEMA_FORM_MODAL_CONFIG,
  title: 'New Schema',
  TitleIcon: PlusCircleIcon,
  submitLabel: 'Create Schema',
};

export default function CreateSchemaModal({ open, onClose }) {
  const onSubmit = useCreateSchema({ onClose });

  return (
    <FormModal {...STATIC_MODAL_PROPS} onSubmit={onSubmit} onClose={onClose} open={open}>
      <SchemaForm />
    </FormModal>
  );
}

CreateSchemaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
