import React from 'react';
import PropTypes from 'prop-types';

import ActionsCell from 'app/components/data-table/ActionsCell';

import useSchemaRowActionsCellProps from './useSchemaRowActionsCellProps';

export default function SchemaRowActionsCell({ schema }) {
  const actionsCellProps = useSchemaRowActionsCellProps(schema);

  return (
    <ActionsCell
      {...actionsCellProps}
      editButtonDataTestId="editSchemaButton"
      deleteButtonDataTestId="deleteSchemaButton"
    />
  );
}

SchemaRowActionsCell.propTypes = {
  schema: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assignmentsCount: PropTypes.number.isRequired,
  }).isRequired,
};
