const buildGoogleGtagSettings = data => {
  const tagId = data['sync-parentGTag-parentTagID'];
  const consentModeEnabled = data['sync-parentGTag-consentModeEnabled'];
  const explicitConsent = data['sync-parentGTag-explicitConsent'];
  const googleAnalytics4ConsentMode = data['sync-parentGTag-googleAnalytics4ConsentMode'];

  const consentModeEnabledBool = consentModeEnabled === 'true';
  const explicitConsentBool = explicitConsent === 'true';

  if (!tagId) {
    return {};
  }

  return {
    googleGtagSettings: {
      tagID: tagId,
      consent: {
        enabled: consentModeEnabledBool,
        explicitConsent: explicitConsentBool,
        syncCategories: {
          googleAnalytics4: googleAnalytics4ConsentMode ? JSON.parse(googleAnalytics4ConsentMode) : [],
        },
      },
    },
  };
};

export default buildGoogleGtagSettings;
