import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const mutation = auditLogMutation;

export function buildVariables({ id, pipelineId, pipelineName, schemaLibraryId, schemaLibraryName }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      id,
      pipelineId,
      pipelineName,
      schemaLibraryId,
      schemaLibraryName,
    }),
  };
}
