import React from 'react';
import pt from 'prop-types';
import { toString as _toString } from 'lodash';

import { MrInputMultiSelect, MrInputNumber, MrInputText, MrInputTextarea, MrToggleText } from '@ion/components';
import { getSyncFieldName } from './helpers';

export const SelectInputFieldType = ({
  fieldName,
  required,
  type,
  defaultFieldValue,
  syncName,
  validateRequired,
  validateField,
  validateNumber,
  formState,
  resetError,
  setField,
  options,
  optionName,
}) => {
  const inputName = getSyncFieldName(syncName, fieldName);
  const defaultValue = defaultFieldValue ?? '';
  const requiredValidations = required ? [validateRequired()] : [];
  switch (type) {
    case 'NUMBER':
      requiredValidations.push(validateNumber());
      return (
        <MrInputNumber
          onChange={setField}
          value={formState[inputName]?.value ?? defaultValue}
          onBlur={validateField}
          onInput={resetError}
          name={inputName}
          errorMsg={formState[inputName]?.errorMsg}
          validators={requiredValidations}
        />
      );
    case 'TEXT_AREA':
      return (
        <MrInputTextarea
          onChange={setField}
          value={formState[inputName]?.value ?? JSON.stringify(defaultValue)}
          onBlur={validateField}
          onInput={resetError}
          name={inputName}
          errorMsg={formState[inputName]?.errorMsg}
          validators={requiredValidations}
        />
      );
    case 'BOOL':
      return (
        <MrToggleText
          onChange={setField}
          name={inputName}
          value={_toString(formState[inputName]?.value ?? defaultValue)}
          options={{
            option1: { name: 'OFF', value: 'false' },
            option2: { name: 'ON', value: 'true' },
          }}
        />
      );
    case 'MULTI_SELECT':
      return (
        <MrInputMultiSelect
          onChange={setField}
          defaultValue={formState[inputName]?.value ?? defaultValue}
          onBlur={validateField}
          onInput={resetError}
          name={inputName}
          errorMsg={formState[inputName]?.errorMsg}
          validators={requiredValidations}
          options={options}
          alphabetized={false}
          optionName={optionName}
        />
      );

    default:
      return (
        <MrInputText
          onChange={setField}
          value={formState[inputName]?.value ?? defaultValue}
          onBlur={validateField}
          onInput={resetError}
          name={inputName}
          errorMsg={formState[inputName]?.errorMsg}
          validators={requiredValidations}
        />
      );
  }
};
SelectInputFieldType.propTypes = {
  fieldName: pt.string.isRequired,
  defaultFieldValue: pt.any,
  type: pt.string.isRequired,
  required: pt.bool.isRequired,
  resetError: pt.func.isRequired,
  formState: pt.object.isRequired,
  setField: pt.func.isRequired,
  syncName: pt.string.isRequired,
  validateField: pt.func.isRequired,
  validateNumber: pt.func.isRequired,
  validateRequired: pt.func.isRequired,
  options: pt.array,
  optionName: pt.string,
};
