function extractPipelineSchemaLibraryData({ pipeline, schemaLibraryVersion }) {
  const { id: pipelineId, name: pipelineName, writekey, clusterId } = pipeline;
  const { id: schemaLibraryVersionId, schemaLibrary, eventSchemas } = schemaLibraryVersion;
  const { name: schemaLibraryName } = schemaLibrary;

  return {
    pipelineId,
    pipelineName,
    writekey,
    clusterId,
    schemaLibraryVersionId,
    schemaLibraryName,
    eventSchemas,
  };
}

export default extractPipelineSchemaLibraryData;
